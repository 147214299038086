import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  identityApiRef,
  oauthRequestApiRef
} from '@backstage/core-plugin-api';
import { 
  microsoftPublicAuthApiRef, MicrosoftPublicAuth,
  microsoftCrewAuthApiRef, MicrosoftCrewAuth,
  microsoftPublicCrewAuthApiRef, MicrosoftPublicCrewAuth,
  microsoftDamsAuthApiRef, MicrosoftDamsAuth, 
  microsoftPublicDamsAuthApiRef, MicrosoftPublicDamsAuth, 
  microsoftIotcftoolAuthApiRef, MicrosoftIotcftoolAuth,
} from '@internal/plugin-cbs-auth';
import { cbsToolsApiRef } from '@internal/plugin-cbs-tools';
import { CBSToolsBackendClient } from '@internal/plugin-cbs-tools';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: cbsToolsApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ discoveryApi, identityApi }) =>
      new CBSToolsBackendClient({ discoveryApi, identityApi }),
  }),
  createApiFactory({
    api: microsoftPublicAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftPublicAuth.create({
      discoveryApi,
      oauthRequestApi,
      environment: configApi.getOptionalString('auth.environment'),
      configApi: configApi,
    }),
  }),
  /*
  * Add here the new provider factories - Search tag: [NEW_TOKEN_PROVIDER] - #9
  */
  createApiFactory({
    api: microsoftCrewAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftCrewAuth.create(
      configApi.getOptionalString('cbsChimeraSettings.crewApplicationId'),
      {
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
        configApi: configApi,
      }
    ),
  }),
  createApiFactory({
    api: microsoftPublicCrewAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftPublicCrewAuth.create(
      configApi.getOptionalString('cbsChimeraSettings.crewApplicationId'),
      {
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
        configApi: configApi,
      }
    ),
  }),
  createApiFactory({
    api: microsoftDamsAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftDamsAuth.create(
      configApi.getOptionalString('cbsChimeraSettings.damsApplicationId'),
      {
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
        configApi: configApi,
      }
    ),
  }),
  createApiFactory({
    api: microsoftPublicDamsAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftPublicDamsAuth.create(
      configApi.getOptionalString('cbsChimeraSettings.damsApplicationId'),
      {
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
        configApi: configApi,
      }
    ),
  }),
  createApiFactory({
    api: microsoftIotcftoolAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) => MicrosoftIotcftoolAuth.create(
      configApi.getOptionalString('cbsChimeraSettings.iotcftoolApplicationId'),
      {
        discoveryApi,
        oauthRequestApi,
        environment: configApi.getOptionalString('auth.environment'),
        configApi: configApi,
      }
    ),
  }),
  /*
  * ----------
  */
];
