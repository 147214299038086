//  ###########################
//  ##    CBS routed tabs    ##
//  ###########################

import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { matchRoutes, useNavigate, useParams, useRoutes } from 'react-router';
import { Content, HeaderTabs } from '@backstage/core-components';
import { SubRoute } from './types';

function useSelectedSubRoute(subRoutes: SubRoute[], select?: number): {
  index: number;
  route: SubRoute;
  element: JSX.Element;
} {
  const params = useParams();

  const routes = subRoutes.map(({ path, children }) => ({
    caseSensitive: false,
    path: `${path}/*`,
    element: children,
  }));

  // If not received explicitly, the first tab specifying _select will be selected by default
  let defaultIndex = select ? select : subRoutes.findIndex(t => t.select === true);
  defaultIndex = Math.min(Math.max(0, defaultIndex), routes.length - 1);

  // TODO: remove once react-router updated
  const sortedRoutes = routes.sort((a, b) =>
    // remove "/*" symbols from path end before comparing
    b.path.replace(/\/\*$/, '').localeCompare(a.path.replace(/\/\*$/, '')),
  );

  const element = useRoutes(sortedRoutes) ?? subRoutes[defaultIndex].children;

  // TODO(Rugvip): Once we only support v6 stable we can always prefix
  // This avoids having a double / prefix for react-router v6 beta, which in turn breaks
  // the tab highlighting when using relative paths for the tabs.
  let currentRoute = params['*'] ?? '';
  if (!currentRoute.startsWith('/')) {
    currentRoute = `/${currentRoute}`;
  }

  const [matchedRoute] = matchRoutes(sortedRoutes, currentRoute) ?? [];
  const foundIndex = matchedRoute
    ? subRoutes.findIndex(t => `${t.path}/*` === matchedRoute.route.path)
    : defaultIndex;

  return {
    index: foundIndex === -1 ? 0 : foundIndex,
    element,
    route: subRoutes[foundIndex] ?? subRoutes[0],
  };
}

export function CBSRoutedTabs(props: { routes: SubRoute[], select?: number }) {
  const { routes, select } = props;
  const navigate = useNavigate();
  const { index, route, element } = useSelectedSubRoute(routes, select);
  const headerTabs = useMemo(() =>
    routes.map(t => ({
      id: t.path,
      label: t.title,
      tabProps: t.tabProps,
    })), 
    [routes]
  );

  const onTabChange = (tabIndex: number) => {
    let { path } = routes[tabIndex];
    // Remove trailing /*
    path = path.replace(/\/\*$/, '');
    // And remove leading / for relative navigation
    path = path.replace(/^\//, '');
    // Note! route resolves relative to the position in the React tree,
    // not relative to current location
    navigate(path);
  };

  return (
    <>
      <HeaderTabs
        tabs={headerTabs}
        selectedIndex={index}
        onChange={onTabChange}
      />
      <Content>
        <Helmet title={route.title} />
        {element}
      </Content>
    </>
  );
}
