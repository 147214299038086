import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const cbsSettingsPlugin = createPlugin({
  id: 'cbs-settings',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsSettingsPage = cbsSettingsPlugin.provide(
  createRoutableExtension({
    name: 'CbsSettingsPage',
    component: () =>
      import('./components/CBSSettingsPage').then(m => m.CBSSettingsPage),
    mountPoint: rootRouteRef,
  }),
);
