//  ##########################
//  ##    CBS FilterCard    ##
//  ##########################

import React, { FC, useEffect, useRef } from 'react';
import { Button, Card, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, makeStyles, MenuItem, OutlinedInput, Select, SvgIcon, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CBSItemDictionary } from './types';
import FilterLightContained from '../images/FilterLightCaption.png';
import FilterDarkContained from '../images/FilterDarkCaption.png';
import FilterLightOutlined from '../images/FilterLight.png';
import FilterDarkOutlined from '../images/FilterDark.png';
import * as TOOLKIT from '../utils/CBSToolkit';
  
//  ==========[ Types ]==========

export type FilterCardInfo = {
  filters: FilterElement[];
};

export type FilterElement = {
  id: number;
  title: string;
  name: string;
  value: string;
  type: string;
  select?: readonly CBSItemDictionary[];
  separators?: RegExp;
  hint?: string;
  parseFn?: (value: string) => string;
};

//  =================================
//            Object caller
//  =================================

interface CBSFiltrCardProps {
  filterInfo: FilterCardInfo;
  filterConfirmDelegate: (confirmFn: () => void) => void;
  filterApplyCallback: (filterInfo: FilterCardInfo) => void;
}

export const CBSFilterCard: FC<CBSFiltrCardProps> = ({ filterInfo, filterConfirmDelegate, filterApplyCallback }) => {

  //  ----------[ Constants ]---------

  const BASE_SPACING: number = 14;

  const MULTIFILTER_MIN_ROWS: number = 2;
  const MULTIFILTER_MAX_ROWS: number = 6;

  //  ----------[ Styles ]---------

  const selectClasses = makeStyles({
    selectIcon: {
      position: "relative",
      marginLeft: '-18px'   // Tricky ... without this, the select control's arrow button will not be clickable 
    }
  })();

  const darkTheme = TOOLKIT.useDarkThemePalette();

  //  ----------[ Hooks ]---------
  
  const [currentValues, setCurrentValues] = React.useState(filterInfo.filters);
  const [filterChanged, setFilterChanged] = React.useState(false);
  const [filterUpdater, setFilterUpdater] = React.useState(0);

  const textFieldRef = useRef<HTMLDivElement[]>([]);

  //  ----------[ Utility functions ]----------

  const updateFilter = () => {
    filterApplyCallback({ 
      filters: filterInfo.filters 
    });
  }
  
  //  ----------
  
  const isFilterChanged = (id: number, value: string) => {
    let changed = false; 
    currentValues.forEach((element) => {
      if (element.id === id) {  // Hook values are not immediately updated!
        if (filterInfo.filters[element.id].value !== value) {
          changed = true;
        }
      }
      else if (filterInfo.filters[element.id].value !== element.value) {
        changed = true;
      }
    });
    setFilterChanged(changed);
  }

  //  ----------

  const handleFilter = () => {
    let update = false; 
    currentValues.forEach((element) => {
      if (filterInfo.filters[element.id].value !== element.value) {
        filterInfo.filters[element.id].value = element.value;
        update = true;
      }
    });
    if (update) {
      updateFilter();
    }
    setFilterChanged(false);
  };

  //  ----------

  const handleClearAll = () => {
    let update = false; 
    currentValues.forEach((element) => {
      if (filterInfo.filters[element.id].value !== "") {
        filterInfo.filters[element.id].value = "";
        update = true;
      }
    });
    setCurrentValues(currentValues.map((element) => {
      return { ...element, value: "" } 
    }));
    if (update) {
      updateFilter();
    }
    setFilterChanged(false);
  };

  //  ----------[ Handler updater ]----------

  useEffect(() => {
    if (filterUpdater > 0) {
      filterConfirmDelegate(handleFilter);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterUpdater]);

  //  ----------[ Handlers ]----------

  const handleFilterButton = () => {
    filterConfirmDelegate(handleFilter);
  };

  //  ----------

  const handleClearAllButton = () => {
    filterConfirmDelegate(handleClearAll);
  };

  //  ----------

  const handleClearFieldButton = (id: number) => {
    setCurrentValues(currentValues.map((element) => {
      return element.id === id ? { 
        ...element, value: "" 
      } : {
        ...element 
      }
    }));
    isFilterChanged(id, "");
    setFilterUpdater(filterUpdater + 1);  // To wait for _currentValues to actually be updated
  };

  //  ----------

  const handleFilterOnChange = (id: number, event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentValues(currentValues.map((element) => {
      return element.id === id ? { 
        ...element, value: event.target.value as string 
      } : {
        ...element 
      }
    }));
    isFilterChanged(id, event.target.value as string);
  };

  const handleFilterKeyDown = (id: number, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      textFieldRef.current[id].blur();
      filterConfirmDelegate(handleFilter);
    }
  }

  //  ----------

  const handleComboOnChange = (id: number, event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentValues(currentValues.map((element) => {
      return element.id === id ? { 
        ...element, value: event.target.value as string 
      } : {
        ...element 
      }
    }));
    isFilterChanged(id, event.target.value as string);
    setFilterUpdater(filterUpdater + 1);  // To wait for _currentValues to actually be updated
  }

  //  ----------[ Components ]----------

  const clearFieldButton = (id: number) => {
    return (
      <InputAdornment position="end">
        <IconButton edge="end" size="small" style={{ padding: 0, marginLeft: -12, fontSize: 12 }}
          onClick={() => handleClearFieldButton(id)}
          onMouseDown={() => handleClearFieldButton(id)}
        >
          <SvgIcon fontSize="inherit" color="inherit">
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </InputAdornment>    
    );
  }

  //  ----------

  const filterField = (filter: FilterElement) => {
    return (
      <TextField inputRef={(ref) => { textFieldRef.current[filter.id] = ref }} key={filter.id} id={`filter${filter.id}`} 
        label={filter.title} variant="outlined" size="small" fullWidth style={{ marginBottom: BASE_SPACING }}
        multiline={filter.separators !== undefined} minRows={filter.separators !== undefined ? MULTIFILTER_MIN_ROWS : undefined} maxRows={filter.separators !== undefined ? MULTIFILTER_MAX_ROWS : undefined}
        helperText={filter.separators !== undefined && filter.hint !== undefined ? filter.hint : undefined}
        InputProps={{ endAdornment: clearFieldButton(filter.id) }} InputLabelProps={{ shrink: true }} 
        value={currentValues[filter.id].value}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleFilterOnChange(filter.id, event)}
        onKeyUp={(event) => handleFilterKeyDown(filter.id, event)}
      />
    );
  };

  //  ----------

  const filterCombo = (filter: FilterElement) => {
    return (
      <FormControl key={`form${filter.id}`} variant="outlined" fullWidth size="small" style={{ marginBottom: BASE_SPACING }}>
        <InputLabel key={`input${filter.id}`} id={`label${filter.id}`} shrink>
          {filter.title}
        </InputLabel>
        <Select key={filter.id} id={`combo${filter.id}`} labelId={`label${filter.id}`}
          classes={{ icon: selectClasses.selectIcon }} input={<OutlinedInput notched label={filter.title} endAdornment={clearFieldButton(filter.id)} />}
          value={currentValues[filter.id].value}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleComboOnChange(filter.id, event)}
        >
          {filter.select!.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  //  ----------

  const filterFieldArray = () => {
    return filterInfo.filters.map(element => {
      if (element.select !== undefined) {
        return filterCombo(element);
      }
      return filterField(element);
    });
  }

  //  ----------

  const filterButton = () => {
    let iconName: string;
    if (darkTheme) {
      iconName = filterChanged ? FilterDarkContained : FilterDarkOutlined;
    }
    else {
      iconName = filterChanged ? FilterLightContained : FilterLightOutlined;
    }
    return (
      <Button variant={filterChanged ? "contained" : "outlined"} color="primary" size="medium" fullWidth startIcon={<img src={iconName} alt="F" width="12" />}
        onClick={() => handleFilterButton()}
      >
        FILTER
      </Button>
    );
  }

  //  ----------[ Filter card object ]----------

  return (
    <Card elevation={2} style={{ height: '100%'}}>
      <Grid container direction="row" alignItems="center" justifyContent='space-between' style={{ padding: "12px 16px 12px 16px" }}>
        <Grid item>
          <Typography variant="h6" style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold', paddingLeft: '6px' }}>
            Filters
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" size="small" style={{ height: 30, paddingTop: 6 }}
            onClick={() => handleClearAllButton()}
          >
            CLEAR ALL
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container direction="column" alignItems="center" style={{ padding: 16 }}>
        <Grid item>
          {filterFieldArray()}
          <Divider style={{ marginTop: 2,  marginBottom: BASE_SPACING }} />
          {filterButton()}
        </Grid>
      </Grid>
    </Card>
  );
}

