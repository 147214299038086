//  ##################################
//  ##    CBS Radio Button Group    ##
//  ##################################

import React, { FC, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { CBSItemDictionary } from '../tables/types';

//  =================================
//            Object Caller
//  =================================

interface CBSRadioButtonGroupProps {
  items: readonly CBSItemDictionary[];
  value: any;
  userData: any;
  callback: (value: any, userData: any) => void;
}
  
export const CBSRadioButtonGroup: FC<CBSRadioButtonGroupProps> = ({ items, value, userData, callback }) => {

  //  ----------[ Hooks ]---------

  const [radio, setRadio] = useState(value);

  //  ----------[ Handlers ]----------

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadio((event.target as HTMLInputElement).value);
    callback((event.target as HTMLInputElement).value, userData);
  };

  //  ----------[ Group constructions ]----------

  const buttons: JSX.Element[] = [];
  items.forEach(dictionary => {
    buttons.push(<FormControlLabel key={dictionary.value} value={dictionary.value} control={<Radio size='small' color="primary"/>} label={dictionary.description} />);
  });

  //  ----------[ Radio button group object ]----------

  return (
    <FormControl>
      <RadioGroup aria-labelledby="radio-buttons-group" value={radio} name="radio-buttons-group" onChange={handleChange}>
        {buttons}
      </RadioGroup>
    </FormControl>
  );
};
    