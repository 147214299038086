//  ##################################
//  ##    CBS CancelConfirmAlert    ##
//  ##################################

import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { CBSThemeHeader } from './CBSThemeHeader';

//  ==========[ Types ]==========

export type CBSCancelConfirmAlertHooks = {
  open: boolean;
  confirmFn?: () => void;
  userFn?: () => void;
};

//  =================================
//            Object caller
//  =================================

interface CBSCancelConfirmAlertProps {
  title: string;
  message: string;
  themeId: string;
  hooks: CBSCancelConfirmAlertHooks;
  setHooks: Function;
};

export const CBSCancelConfirmAlert = ({ title, message, themeId, hooks, setHooks }: CBSCancelConfirmAlertProps) => {
 
  //  ----------[ Constants ]---------

  const SIDE_TITLE_MARGIN: number = 16;
  const BUTTON_MIN_WIDTH: number = 120;
  const BUTTON_MARGIN_RIGHT: number = 18;
  const BUTTON_VERTICAL_MARGIN: number = 12;

  //  ----------[ Components ]----------

  const cancelButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        <Button variant="contained" color="primary" fullWidth
          onClick={() => setHooks({ ...hooks, open: false })} 
        >
          CANCEL
        </Button>
      </Box>
    );
  };

  //  ----------
  
  const confirmButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        <Button variant="contained" color="secondary" fullWidth
          onClick={() => {
            setHooks({ ...hooks, open: false });
            if (hooks.confirmFn) {
              if (hooks.userFn) {
                hooks.userFn();
              }
              hooks.confirmFn();
            }
          }} 
        >
          CONFIRM
        </Button>
      </Box>
    );
  };

  //  ----------[ Cancel confirm alert object ]----------

  return (
    <Dialog maxWidth="xl"
      open={hooks.open} 
      onClose={() => setHooks({ ...hooks, open: false })} 
      aria-labelledby="dialog-title"
      aria-describedby="dialog-message"
    >
      <DialogTitle id="dialog-title" style={{ padding: 0, margin: 0 }}>
        <CBSThemeHeader 
          title={title}
          themeId={themeId} 
          size="small"
        />
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ marginLeft: SIDE_TITLE_MARGIN, marginRight: SIDE_TITLE_MARGIN, marginTop: 30, marginBottom: 16 }}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        {confirmButton()}
        {cancelButton()}
      </DialogActions>
    </Dialog>
  );  
}
