import { createApiRef } from '@backstage/core-plugin-api';

export interface CBSToolsApi {
  getHealth(): Promise<any>;
  getPackageList(path: string): Promise<any>;
  getOutdatedList(path: string): Promise<any>;
  downloadFile(path: string): Promise<any>;
}

export const cbsToolsApiRef = createApiRef<CBSToolsApi>({
  id: 'plugin.tools.service',
});