import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const cbsAuthPlugin = createPlugin({
  id: 'cbs-auth',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsAuthPage = cbsAuthPlugin.provide(
  createRoutableExtension({
    name: 'CbsAuthPage',
    component: () =>
      import('./components/AuthComponent').then(m => m.AuthComponent),
    mountPoint: rootRouteRef,
  }),
);
