import { CBSToolsApi } from './types';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';

export class CBSToolsBackendClient implements CBSToolsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) { 
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  //  ----------[ Utility methods ]----------

  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    const json = await response.json();
    if (json.error !== null) {
      throw new Error(`${json.error} - ${json.cmd}`);
    }
    return json;
  }

  //  ----------

  private async getInternal(url: string, path: string): Promise<any> {
    const { token } = await this.identityApi.getCredentials();
    const response = await fetch(url, {
      headers: { 
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({path}),
    });
    return await this.handleResponse(response);
  }

  //  ----------[ Get health ]----------

  async getHealth(): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl('tools')}/getHealth`;
    const response = await fetch(url, {
      method: 'GET',
    });
    return await this.handleResponse(response);
  }

  //  ----------[ Get package list ]----------

  async getPackageList(path: string): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl('tools')}/getPackageList`;
    return await this.getInternal(url, path);
  }

  //  ----------[ Get outdated list ]----------

  async getOutdatedList(path: string): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl('tools')}/getOutdatedList`;
    return await this.getInternal(url, path);
  }

  //  ----------[ Download file ]----------

  async downloadFile(path: string): Promise<string[]> {
    const url = `${await this.discoveryApi.getBaseUrl('tools')}/downloadFile`;
    return await this.getInternal(url, path);
  }
}