//  ############################
//  ##    CBS Simple Alert    ##
//  ############################

import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

//  =================================
//            Object Caller
//  =================================

interface CBSSimpleAlertArgs {
  title: string;
  message: string;
  open: boolean;
  setOpen: Function;
}
  
export const CBSSimpleAlert: FC<CBSSimpleAlertArgs> = ({ title, message, open, setOpen }) => {
  return (
    <Dialog 
      open={open} 
      onClose={() => setOpen(false)} 
      aria-labelledby="dialog-title"
      aria-describedby="dialog-message"
    >
      <DialogTitle id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="message-title">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={() => setOpen(false)} 
          color="primary" 
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
