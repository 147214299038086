//  ##############################
//  ##    CBS TokenViewer       ##
//  ##############################
import React from 'react';
import useAsync from 'react-use/esm/useAsync';
import { useApi } from '@backstage/core-plugin-api';
// import { ApiRef, BackstageIdentityApi, OAuthApi, OpenIdConnectApi, ProfileInfoApi, SessionApi, useApi } from '@backstage/core-plugin-api';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Copy from '@material-ui/icons/FileCopyOutlined';
import { MicrosoftAuthApiRefType } from '@internal/plugin-cbs-auth';

const useToolsPageStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      minWidth: '50%'
    },          
    closeIcon: {
      fontSize: 20
    },
    resize:{
      fontSize: 12,
      fontFamily: 'monospace'
    }
  })
);

interface CbsTokenViewerProps {
  open: boolean;
  title: string;
  authApiRef: MicrosoftAuthApiRefType;
  // authApiRef: ApiRef<OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi>;
  onClose: () => void;
}

//  ===============================
//            Main Object 
//  ===============================
export const CBSTokenViewer = ({open, title, authApiRef, onClose }: CbsTokenViewerProps) => {

  const authApi = useApi(authApiRef);    
  const styles = useToolsPageStyles();  

  const [token, setToken] = React.useState('');  

  useAsync(async () => {
    if (open) {    
      const accessToken = await authApi.getAccessToken();

      setToken(accessToken);
    }
  }, [open]);  

  return (
    <Dialog open={open} onClose={() => onClose()} classes={{ paper: styles.dialogPaper }} >
      <DialogTitle>    
        <Grid container direction="row" alignItems="flex-end" justifyContent='space-between' spacing={0}>
          <Grid item>
            <Typography variant="h5">{title}</Typography>        
          </Grid>
          <Grid item>
            <IconButton key="dismiss" title="Close page" onClick={() => onClose()} color="inherit" >
              <Close className={styles.closeIcon} />
            </IconButton>          
          </Grid>        
        </Grid>
      </DialogTitle>
      <DialogContent style={{marginTop: '-10px'}} dividers>
        <TextField id='token' 
          variant='outlined' 
          defaultValue={token} 
          disabled 
          fullWidth 
          multiline 
          size='small' 
          InputProps={{
            classes: {
              input: styles.resize,
            }
          }}
        />
      </DialogContent>      
      <DialogActions>
        <Button variant="contained" color="primary" startIcon={<Copy />} 
          onClick={() => 
          {
            navigator.clipboard.writeText(token);
            onClose();
          }}>Copy</Button>        
      </DialogActions>      
    </Dialog>
  );
};
