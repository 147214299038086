import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const cbsCorePlugin = createPlugin({
  id: 'cbs-core',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsCorePage = cbsCorePlugin.provide(
  createRoutableExtension({
    name: 'CbsCorePage',
    component: () =>
      import('./components/CoreComponent').then(m => m.CoreComponent),
    mountPoint: rootRouteRef,
  }),
);
