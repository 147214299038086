import React, { useState } from 'react';
import { BackstageIdentityResponse, SignInPageProps, useApi } from '@backstage/core-plugin-api';
import { UserIdentity, Progress, Content, Page, SignInProviderConfig } from '@backstage/core-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMountEffect } from '@react-hookz/web';
import { Box, makeStyles } from '@material-ui/core';
import ChimeraLogoFull from './images/Chimera.png';
import { isInternalProvider } from 'backstage-plugin-cbs-common';
  
const useStyles = makeStyles(
  {
    container: {
      padding: 0,
      listStyle: 'none',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '400px',
      margin: 0,
      padding: 0,
    },
    logo: {
      maxHeight: 275,
      maxWidth: 240
    },  
  },
  { name: 'BackstageSignInPage' },
);

type SignInProviderConfigPair = {
  default: SignInProviderConfig;
  public: SignInProviderConfig;
};

type SingleSignInPageProps = SignInPageProps & {
  providers: SignInProviderConfigPair;
  auto?: boolean;
  title?: string;
  align?: 'center' | 'left';
};

export type Props = SingleSignInPageProps;
  
export const SingleSignInPage = ({ providers, auto, onSignInSuccess }: SingleSignInPageProps) => {
  const provider = isInternalProvider() ? providers.default : providers.public;
  const classes = useStyles();
  const authApi = useApi(provider.apiRef);
  // const configApi = useApi(configApiRef);

  const [error, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

  type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {
    try {
      let identityResponse: BackstageIdentityResponse | undefined;
      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({
          optional: true,
        });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && (showPopup || auto)) {
        // Unless auto is set to true, this step should not happen.
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setShowLoginPage(true);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support sign-in`,
          );
        }
      }

      if (!identityResponse) {
        setShowLoginPage(true);
        return;
      }

      const profile = await authApi.getProfile();
      onSignInSuccess(
        UserIdentity.create({
          identity: identityResponse.identity,
          authApi,
          profile,
        }),
      );
    } catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setShowLoginPage(true);
    }
  };

  useMountEffect(() => login({ checkExisting: true }));

  return (
    <Page themeId="app">
      <Content>
        <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}
          style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <Grid item>
            <img src={ChimeraLogoFull} className={classes.logo} alt="Chimera" />
          </Grid>
          <Grid item>
            {showLoginPage ? 
              <Button color="primary" variant="outlined" style={{ marginTop: 20 }}
                onClick={() => login({ showPopup: true })}
              >
                SIGN IN
              </Button>
            :
              <Box sx={{minWidth: 240, marginTop: 20}}>
                <Progress />
              </Box>
            }
          </Grid>
          {error && error.name !== 'PopupRejectedError' && 
            <Grid item>
              <Typography variant="body1" color="error" style={{ marginTop: 20 }}>
                {error.message}
              </Typography>
            </Grid>
          }
        </Grid>
      </Content>
    </Page>
  );
};

export function SignInPage(props: Props) {
  return <SingleSignInPage {...props} />;
}
