import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

// ---------- NEW ----------
export const cbsCrewPlugin = createPlugin({
  id: 'cbs-crew',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsCrewPage = cbsCrewPlugin.provide(
  createRoutableExtension({
    name: 'CbsCrewPage',
    component: () =>
      import('./components/CrewComponent').then(m => m.CrewComponent),
    mountPoint: rootRouteRef,
  }),
);
