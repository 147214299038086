//  ###########################
//  ##    CBS DetailPopup    ##
//  ###########################

import  React, { useEffect, useRef, useState } from "react"
import useAsync from 'react-use/esm/useAsync';
import { identityApiRef, ProfileInfo, useApi } from '@backstage/core-plugin-api';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, Checkbox, CircularProgress, FormControl, Grid, InputLabel, Link, MenuItem, OutlinedInput, Select, Tab, TextField, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import { CBSThemeHeader } from '../elements/CBSThemeHeader';
import { CBSFetchParams, CBSDetailElementProps, DetailSectionProps, DetailTabListProps, CBSDetailProps, CBSDetailElementRules, CBSLinkArguments } from './types';
import { composeErrorMessage, linkArgumentsCompose } from './common';
import { filterWithDictionary, titleWithDictionary, useScrollbarWidth } from './common';
import * as TOOLKIT from '../utils/CBSToolkit';
import { NavLink } from "react-router-dom";
import { MicrosoftAuthApiRefType } from "@internal/plugin-cbs-auth";
import { CBSBasicTable } from "./CBSBasicTable";
import { CBSRadioButtonGroup } from "../elements/CBSRadioButtonGroup";

//  ==========[ Types ]==========

export type DetailHooks = {
  open: boolean;
  value: any;
  mode: number;
  reload: number;
};

//  ==========[ Constants ]==========

export const DETAIL_MODE_SHOW: number = 0;
export const DETAIL_MODE_EDIT: number = 1;
export const DETAIL_MODE_CREATE: number = 2;

//  ==========[ Globals ]==========

let detailData: any = undefined;
// kkkkzzzz++++
let changedData: any = undefined;
// kkkkzzzz++++
const detailSections: DetailSectionProps[] = [];
const detailTabList: DetailTabListProps[] = [];

//  =================================
//            Object caller
//  =================================

export const CBSDetailPopup = (
  props: CBSDetailProps, 
  hooks: DetailHooks, 
  setHooks: Function,
  themeId: string, 
  baseUrl: string, 
  readListParams: () => CBSFetchParams,
  keyFields: string[],
  authApiRef?: MicrosoftAuthApiRefType
) => {

  //  ----------[ Constants ]---------

  const SIDE_TITLE_MARGIN: number = 8;
  const SIDE_EXTERNAL_MARGIN: number = 12;
  const SIDE_INTERNAL_SPACING: number = 12;
  const SECTION_VERTICAL_SPACING: number = 12;
  const SECTION_VERTICAL_PADDING: number = 24;
  const DIVIDER_VERTICAL_SPACING: number = 6;
  const EDIT_FIELD_SPACING: number = 4;
  const USER_DIVIDER_HEIGHT: number = 12;

  const ALERT_TOP_SPACING: number = 60;
  const ALERT_BOTTOM_SPACING: number = 40;
  const PROGRESS_VERTICAL_SPACING: number = 40;

  const BUTTON_MIN_WIDTH: number = 120;
  const BUTTON_MARGIN_RIGHT: number = 18;
  const BUTTON_VERTICAL_MARGIN: number = 16;

  const REQUIRED_STD_LABEL: string = "Required *";
  const INCORRECT_ENTRY_LABEL: string = "Incorrect entry";

  const NO_ERRORS: number = 0;
  const REQUIRED_ERROR: number = 1;
  const INCORRECT_ERROR: number = 2;

  //  ----------[ Hooks ]---------

  const [saveEnabled, setSaveEnabled] = useState(false);
  const [currentValues, setCurrentValues] = useState<CBSDetailElementProps[]>();
  const textFieldRef = useRef<HTMLDivElement[]>([]);

  const [saving, setSaving] = useState(false);
  const [obsessiveCheck, setObsessiveCheck] = useState(false);
  const [saveError, setSaveError] = useState<string>();
  const [fieldError, setFieldError] = useState(false);
  const [value, setValue] = useState("1");
  // kkkkzzzz++++
  const [update, setUpdate] = useState(0);
  // kkkkzzzz++++

  //  ----------[ Objects ]---------

  const urlPattern = /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/g

  //  ----------[ Styles ]---------

  const styleClasses = TOOLKIT.useColorStyles();
  const scrollbarWidth = useScrollbarWidth();

  //  ----------[ Service settings ]---------

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authApi = authApiRef ? useApi(authApiRef) : undefined;
  const identityApi = useApi(identityApiRef);

  //  ----------[ Hook utility functions ]----------

  const setTokenOnParams = async (params: CBSFetchParams): Promise<string | undefined> => {
    if (authApi) {
      const token = await authApi.getAccessToken();

      if (params.request?.headers !== undefined) {
        params.request.headers = {...params.request.headers, Authorization: `Bearer ${token}`};
      }
      else {
        params.request = {...params.request, headers: { Authorization: `Bearer ${token}` }};
      }
      
      return token;
    }
    return undefined;
  }

  //  ----------[ Creation mode initialization hook ]----------

  useAsync(async (): Promise<ProfileInfo | undefined> => {
    if (hooks.open && isCreating()) {
      const identity = await identityApi.getProfileInfo();

      detailSections.length = 0;
  
      const values: CBSDetailElementProps[] = [];
      let record: any;
      let id = 0;

      props.createDetail!.forEach((s: any) => {
        const elements = s.function();
        elements.forEach((e: any) => {
          // kkkkzzzz++++
          if (e.field !== undefined) {
          // kkkkzzzz++++
            if (e.value === undefined) {
              if (e.type === 'boolean') {
                e.value = false;
              }
              else if (e.type !== 'datetime') {
                e.value = "";
              }
            }
            e.id = id++;
            e.error = NO_ERRORS;
            values.push(e);
            record = {...record, [e.field]: e.value};
          // kkkkzzzz++++
          }
          if (e.onChange && e.tag) {
            if (!changedData) {
              changedData = {};
            }
            changedData[e.tag] = e.value;
          }
          // kkkkzzzz++++
        });
        detailSections.push({title: s.title, elements: elements});
      });
  
      detailData = record;
      setCurrentValues(values);

      return identity;
    }

    return undefined;
  }, []);

  //  ----------[ Show/Update mode load hook ]---------

  const result = useAsync(async (): Promise<any> => {
    if (hooks.open && !isCreating()) {
      const params = props.readParamsFn(hooks.value);
      const token = await setTokenOnParams(params);

      const response = await fetch(`${baseUrl}${params.url}`, params.request);
      if (response.status !== 200) {
        throw new Error(`Failed to fetch: ${response.url}, status: ${response.status}, reason: ${response.statusText}`);
      }

      const record = await response.json();
      if (params.postQueryFn !== undefined) {
        await params.postQueryFn(record, { baseUrl: baseUrl, token: token });
      }
  
      detailData = record;
      detailSections.length = 0;
      detailTabList.length = 0;

      const values: CBSDetailElementProps[] = [];

      if (isEditable()) {
        let id = 0;
        props.updateDetail!.forEach((s: any) => {
          const elements = s.function(record);
          elements.forEach((e: any) => {
            // kkkkzzzz++++
            if (e.onUpdate) {
              e.onUpdate(e, record);
            }
            // kkkkzzzz++++
            e.id = id++;
            e.error = NO_ERRORS;
            values.push(e);
          });
          detailSections.push({title: s.title, elements: elements});
        });

        setCurrentValues(values);
      }
      else {
        props.readDetail.forEach((s: any) => {
          if (s.detail !== undefined) {
            const sections: DetailSectionProps[] = [];
            s.detail.forEach((d: any) => {
              sections.push({title: d.title, elements: d.function(record)});
            });
            detailTabList.push({title: s.title, sections: sections});
          }
          else {
            detailSections.push({title: s.title, elements: s.function(record)});
          }
        });
      }

      return record;
    }

    return undefined;
  }, [hooks.value]);

  //  ----------[ Size control hooks ]----------

  const [dialogHeight, setDialogHeight] = useState(0);
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dialogRef.current?.clientHeight) {
      if (props.settings.detailMinHeight) {
        setDialogHeight(result.loading || dialogRef.current?.clientHeight >= props.settings.detailMinHeight ? dialogRef.current?.clientHeight : props.settings.detailMinHeight);
      }
      else {
        setDialogHeight(dialogRef.current?.clientHeight);
      }
    }
  }, [dialogRef.current?.clientHeight, result.loading, props.settings.detailMinHeight]);

  //  ----------[ Utility functions ]----------

  function isEditable() {
    return hooks.mode === DETAIL_MODE_EDIT && props.updateDetail !== undefined && props.updateParamsFn !== undefined;
  }

  //  ----------

  function isCreating() {
    return hooks.mode === DETAIL_MODE_CREATE && props.createDetail !== undefined && props.createParamsFn !== undefined;
  }

  //  ----------
  
  function isTabList() {
    return detailTabList.length > 0;
  }

  //  ----------[ Handlers utility functions ]----------

  const verifyFieldCompliance = (element: CBSDetailElementProps) => {
    // kkkkzzzz++++
    if (!element.ignore) {
    // kkkkzzzz++++
      if (element.required && (element.value === undefined || element.value.toString() === "")) {
        return REQUIRED_ERROR;
      }
      if (element.rule === CBSDetailElementRules.VERIFY_URL && !urlPattern.test(element.value)) {
        return INCORRECT_ERROR;
      }
    // kkkkzzzz++++
    }
    // kkkkzzzz++++
    return NO_ERRORS;
  }

  //  ----------

  const verifyAllFieldsCompliance = () => {
    let compliant = true;

    if (currentValues !== undefined) {
      setCurrentValues(currentValues.map((element) => {
        element.error = verifyFieldCompliance(element);
        if (element.error !== NO_ERRORS) {
          compliant = false;
        }
        return element; 
      }));
    }

    setFieldError(!compliant);
    return compliant;
  }

  //  ----------

  const onChangeFunction = (id: number, newvalue: string) => {
    let compliant = true;

    if (currentValues !== undefined) {
      setCurrentValues(currentValues.map((element) => {
        if (element.id === id) {
          if (newvalue !== detailData[element.field!]) {
            setSaveEnabled(true);
          }
          element.value = newvalue;
          if (obsessiveCheck && (element.required || element.rule)) {
            element.error = verifyFieldCompliance(element);
          }
        }
        if (element.error !== NO_ERRORS) {
          compliant = false;
        }
        return element;
      }));
    }
    setFieldError(!compliant);
  }

  //  ----------[ Handlers ]----------

  const handleFieldChange = (id: number, event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeFunction(id, event.target.value as string);
  };

  const handleFieldKeyDown = (id: number, event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      textFieldRef.current[id].blur();
    }
  };
  
  //  ----------

  const handleCheckboxChange = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentValues !== undefined) {
      setCurrentValues(currentValues.map((element) => {
        if (element.id === id) {
          if (event.target.checked !== detailData[element.field!]) {
            setSaveEnabled(true);
          }
          element.value = event.target.checked;
        }
        return element;
      }));
    }
  };

  //  ----------

  const handleComboOnChange = (id: number, event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeFunction(id, event.target.value as string);
  };

  //  ----------

  const handleButtonClick = () => {
    if (currentValues !== undefined) {
      currentValues.forEach((v) => {
        detailData[v.field!] = v.value;
      });
 
      setObsessiveCheck(true);  // We activate the obsessive check on the fields only after the first save attempt

      if (verifyAllFieldsCompliance()) {
        void (async () => {
          setSaving(true);
          try {
            const paramsFn = isCreating() ? props.createParamsFn : props.updateParamsFn;
            // kkkkzzzz++++
            const saveprm = paramsFn!(detailData, changedData);   // Create/Update button is present only if _createParams/_updateParams is defined
            // kkkkzzzz++++
            // const saveprm = paramsFn!(detailData);   // Create/Update button is present only if _createParams/_updateParams is defined
            // kkkkzzzz----
            await setTokenOnParams(saveprm);
            
            const saveresp = await fetch(`${baseUrl}${saveprm.url}`, saveprm.request);
            if (saveresp.status !== 200) {
              throw new Error(`Failed to ${isCreating() ? "create" : "update"}: ${saveresp.url}, status: ${saveresp.status}, reason: ${saveresp.statusText}`);
            }

            // kkkkzzzz++++
            const savejson = await saveresp.json();
            const status = Array.isArray(savejson) ? savejson[0] : savejson;
            // kkkkzzzz++++
            // const status = await saveresp.json();
            // kkkkzzzz----
    
            if (status.isSuccess) {   // We use the table read function, filtering the key of the newly created object
              const params = readListParams();
              await setTokenOnParams(params);
    
              const response = await fetch(`${baseUrl}${params.url}?${filterWithDictionary(isCreating() ? status : hooks.value, keyFields)}`, params.request);
              if (response.status !== 200) {
                throw new Error(`Failed to fetch: ${response.url}, status: ${response.status}, reason: ${response.statusText}`);
              }

              const record = await response.json();
    
              setSaving(false);
              setHooks({ ...hooks, open: false, value: record.items, reload: hooks.reload + 1 });
            }
            else {
              setSaving(false);
              setSaveError(composeErrorMessage(status.detectedProblems));
            }
          }
          catch (error) {
            setSaving(false);
            setSaveError(error instanceof Error ? error.message : "Unknown error");
          }
        })();
      }
    }    
  };

  // kkkkzzzz++++
  //  ----------[ User utility function ]----------

  function getElementByTag(tag: string): CBSDetailElementProps | undefined {
    for (const section of detailSections) {
      for (const element of section.elements) {
        if (element.tag === tag) {
          return element;
        }
      }  
    }
    return undefined;
  };
  // kkkkzzzz++++

  //  ----------[ Title component ]----------

  const detailTitle = () => {
    const title = isEditable() ? "Update" : props.title;
    return (
      <Grid container spacing={0} direction="row" alignItems="baseline">
        <Grid item>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: 'white', marginLeft: SIDE_TITLE_MARGIN }}>
            {!isCreating() ? title : `New ${props.title}`}
          </Typography>
        </Grid>
        {!isCreating() && 
          <Grid item>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: "whitesmoke", marginLeft: 40, marginRight: 12 }}>
              Id :
            </Typography>
          </Grid>
        }
        {!isCreating() && 
          <Grid item>
            <Typography variant="body1" style={{ color: 'white', marginRight: SIDE_TITLE_MARGIN }}>
              {titleWithDictionary(hooks.value, keyFields)}
            </Typography>
          </Grid>
        }
        </Grid>
    );
  };

  //  ----------[ Button components ]----------

  const okCancelButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        <Button variant="contained" color="primary" fullWidth
          onClick={() => setHooks({ ...hooks, open: false })} 
        >
          {hooks.value === undefined || isEditable() ? "CANCEL" : "OK"}
        </Button>
      </Box>
    );
  };

  //  ----------

  const saveButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        {saving ?
          <Button variant="contained" color="secondary" fullWidth disabled >
            <CircularProgress size='1rem' variant='indeterminate' color='inherit' style={{ marginRight: 12}} />
            {isCreating() ? "CREATING" : "SAVING"}
          </Button>
        :
          <Button variant="contained" color="secondary" fullWidth disabled={!saveEnabled}
            onClick={() => handleButtonClick()}
          >
            {isCreating() ? "CREATE" : "SAVE"}
          </Button>
        }
      </Box>
    );
  };

  //  ----------[ Combo component ]----------

  const detailCombo = (element: CBSDetailElementProps) => {
    const elementValue = currentValues ? currentValues[element.id!].value : element.value;
    return (
      <FormControl key={`form${element.id}`} variant="outlined" fullWidth size="small" error={currentValues ? currentValues[element.id!].error !== NO_ERRORS : false}>
        <InputLabel key={`input${element.id}`} id={`label${element.id}`} shrink>
          {element.required ? REQUIRED_STD_LABEL : undefined}
        </InputLabel>
        <Select key={element.id} id={`combo${element.id}`} labelId={`label${element.id}`} 
          input={element.required ? <OutlinedInput notched id={`label${element.id}`} label={REQUIRED_STD_LABEL} /> : undefined}
          value={elementValue === null ? "" : elementValue}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleComboOnChange(element.id!, event)}
        >
          {element.select!.map(option => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.description}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  //  ----------[ Detail element components ]----------

  const linkField = (element: CBSDetailElementProps, elementValue: any) => {
    if (element.linkEnable && !element.linkEnable(detailData)) {
      return (
        <>
          {elementValue.toString()}
        </>
      );
    }

    return (
      <Link 
        component={NavLink}
        to={typeof element.link === 'string' ? `${element.link}/${elementValue}` : linkArgumentsCompose(element.link as CBSLinkArguments)}
        underline="hover"
        color="primary"
      >
        {elementValue.toString()}
      </Link>
    );
  }

  //  ----------

  const elementDisplay = (element: CBSDetailElementProps) => {
    const bottom = element.table !== undefined ? 8 : 0;
    const [vertical, left] = isEditable() || isCreating() ? [8 + EDIT_FIELD_SPACING, 14] : [0, 0];
    const systemProps = { maxWidth: props.settings.fieldValueMaxWidth + scrollbarWidth, marginRight: SIDE_INTERNAL_SPACING, marginTop: vertical, marginBottom: vertical + bottom, marginLeft: left };

    if (element.table !== undefined) {
      return (
        <Box sx={systemProps}>
          {/* kkkkzzzz2++++ */}
          <CBSBasicTable
            columns={element.table?.columns}
            rows={element.value}
            tag={element.name}
            minWidth={element.table?.minWidth}
            maxRows={element.table?.maxRows}
            message={element.table?.message}
          />
          {/* kkkkzzzz2++++ */}
          {/* {element.table?.type === 'paged' ?
            <CBSBasicPagedTable
              columns={element.table?.columns}
              rows={element.value}
              tag={element.name}
              minWidth={element.table?.minWidth}
              maxHeight={element.table?.maxHeight}
            />
          :
            <CBSBasicScrollTable
              columns={element.table?.columns}
              rows={element.value}
              tag={element.name}
              minWidth={element.table?.minWidth}
              maxHeight={element.table?.maxHeight}
              message={element.table?.message}
            />
          } */}
          {/* kkkkzzzz2---- */}
        </Box>
      );
    }

    if (typeof element.value === 'boolean') {
      return (
        <Box sx={systemProps}>
          {element.value ? <CheckIcon /> : <RemoveIcon />}
        </Box>
      );
    }

    const elementValue = element.value !== null && element.value !== undefined ? element.value : '';
    return (
      <Box sx={systemProps}>
        {element.link ?
          <>
            {linkField(element, elementValue)}
          </>
        :
          <Typography className={styleClasses.textArea} variant="body2" style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            {element.type === 'datetime' ?
              new Date(elementValue).toLocaleString() 
            :
              TOOLKIT.splitTextLines(elementValue.toString())
            }
          </Typography>
        }
      </Box>
    );
  };

  //  ----------

  const elementEdit = (element: CBSDetailElementProps) => {
    const systemProps = { minWidth: props.settings.fieldValueMinWidth || 0, maxWidth: props.settings.fieldValueMaxWidth + scrollbarWidth, marginRight: SIDE_INTERNAL_SPACING, marginTop: EDIT_FIELD_SPACING, marginBottom: EDIT_FIELD_SPACING };
    
    if (element.select !== undefined) {
      // kkkkzzzz++++
      if (element.type === 'radio') {
        const radioButtonCallback = (selectedValue: any, userData: any) => {
          userData.onChange(selectedValue, getElementByTag);
          changedData[userData.tag] = selectedValue;
          setUpdate(update + 1);
        };

        return (
          <Box sx={systemProps}>
            <CBSRadioButtonGroup 
              items={element.select}
              value={element.value}
              userData={element}
              callback={radioButtonCallback}
            />
          </Box>
        );
      }
      // kkkkzzzz++++

      return (
        <Box sx={systemProps}>
          {detailCombo(element)}
        </Box>
      );
    }
    if (element.type === 'boolean') {
      return (
        <Box sx={systemProps}>
          <Checkbox color="primary" checked={currentValues ? currentValues[element.id!].value : element.value} 
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(element.id!, event)}
          />
        </Box>
      );
    }

    let label = undefined;
    if (currentValues && currentValues[element.id!].error === INCORRECT_ERROR) {
      label = INCORRECT_ENTRY_LABEL;
    }
    else if (element.required) {
      label = REQUIRED_STD_LABEL;
    }  

    if (element.type === 'datetime') {
      return (
        <Box sx={systemProps}>
        <TextField inputRef={(ref) => { textFieldRef.current[element.id!] = ref; }} id={`edit${element.id}`} 
            variant="outlined" size="small" fullWidth type="datetime-local" 
            label={label} value={currentValues ? currentValues[element.id!].value : element.value}
            InputLabelProps={{ shrink: true }} error={currentValues ? currentValues[element.id!].error !== NO_ERRORS : false}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleFieldChange(element.id!, event)}
            onKeyUp={(event) => handleFieldKeyDown(element.id!, event)}
          />
        </Box>
      );
    }
    
    return (
      <Box sx={systemProps}>
        <TextField inputRef={(ref) => { textFieldRef.current[element.id!] = ref; }} id={`edit${element.id}`} 
          variant="outlined" size="small" fullWidth 
          label={label} inputProps={{ maxLength: element.maxLength }}
          value={currentValues ? currentValues[element.id!].value : element.value}
          InputLabelProps={{ shrink: true }} error={currentValues ? currentValues[element.id!].error !== NO_ERRORS : false}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleFieldChange(element.id!, event)}
          onKeyUp={(event) => handleFieldKeyDown(element.id!, event)}
        />
      </Box>
    );
  };

  //  ----------[ Detail section component ]----------

  const detailSection = (key: string, elements: CBSDetailElementProps[]) => {
    const elementsArray: JSX.Element[] = [];

    const detailElement = (element: CBSDetailElementProps) => {
      if (element.type === 'divider') {
        return (
          <Box sx={{ minHeight: USER_DIVIDER_HEIGHT }} />
        );
      }
  
      return (
        <Grid item key={`${element.name}${key}`}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Box sx={{ width: props.settings.fieldNameWidth, marginLeft: SIDE_INTERNAL_SPACING }}>
                <Typography className={styleClasses.textSubtle} variant="body2">
                  {element.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              {(isEditable() || isCreating()) && !element.disabled ? elementEdit(element) : elementDisplay(element)}
            </Grid>
          </Grid>
        </Grid>
      );
    };

    elements.forEach((element) => {
      // kkkkzzzz++++
      if (!element.hidden) {
        elementsArray.push(detailElement(element));
      }
      // kkkkzzzz++++
      // elementsArray.push(detailElement(element));
      // kkkkzzzz----
    });
      
    return (
      <Grid item key={`item${key}`}>
        <Grid container spacing={1} direction="column" alignItems="flex-start" style={{ marginLeft: SIDE_EXTERNAL_MARGIN, marginRight: SIDE_EXTERNAL_MARGIN }}>
          <Box sx={{ minHeight: SECTION_VERTICAL_SPACING }} />
          {elementsArray}
          <Box sx={{ minHeight: SECTION_VERTICAL_SPACING }} />
        </Grid>
      </Grid>
    );
  };

  //  ----------[ Detail body component ]----------

  const detailBody = (sections: DetailSectionProps[], keyStart?: number) => {
    const sectionsArray: JSX.Element[] = [];
    let key = keyStart ? keyStart * 1000 : 0;
    
    const detailDivider = (title: string) => {
      return (
        <Grid container key={`divider${key.toString()}`} spacing={0} direction="column">
          <Grid item>
            <Typography className={styleClasses.textArea} variant="body1" style={{ fontWeight: "bold", marginTop: DIVIDER_VERTICAL_SPACING, marginLeft: SIDE_EXTERNAL_MARGIN + SIDE_INTERNAL_SPACING }}>
              {title}
            </Typography>
          </Grid>
          <Box className={styleClasses.divider} style={{ marginBottom: DIVIDER_VERTICAL_SPACING }} />
        </Grid>
      );
    };
       
    let count = 0;  // Section elements are managed individually so that they belong to the same container and the dividers have the same length
    sections.forEach((s) => {
      sectionsArray.push(detailDivider(s.title));
      sectionsArray.push(detailSection((++key).toString(), s.elements));
      if (isTabList() && ++count < sections.length) {
        sectionsArray.push(<Box key={(++key).toString()} sx={{ minHeight: SECTION_VERTICAL_PADDING }} />);
      }
    });

    return (
      <Grid container key={`grid${key.toString()}`} spacing={0} direction="column" alignItems="flex-start">
        <Box sx={{ minHeight: SECTION_VERTICAL_PADDING }} />
        {sectionsArray}
      </Grid>
    );
  };

  //  ----------[ TabList body component ]----------

  const tabListBody = (tabList: DetailTabListProps[]) => {
    const tabArray: JSX.Element[] = [];
    const tabPanelArray: JSX.Element[] = [];
    let counter = 0;

    tabList.forEach((t) => {
      tabArray.push(<Tab key={`tab${counter.toString()}`} label={t.title} value={(++counter).toString()} />);
      tabPanelArray.push(
        <TabPanel key={`panel${counter.toString()}`} value={(counter).toString()} style={{ padding: 0 }}>
          {detailBody(t.sections, counter)}
        </TabPanel>
      );
    });

    const handleChange = (newvalue: any) => {
      setValue(newvalue as string);
    };

    return (
      <TabContext value={value}>
        <TabList 
          aria-label="detail-tabs" indicatorColor="primary" style={{ marginBottom: 12 }}
          onChange={(_: React.ChangeEvent<{}>, newvalue: any) => handleChange(newvalue)}
        >
          {tabArray}
        </TabList>
        {tabPanelArray}
      </TabContext>
    );
  }

  //  ----------[ Wait animation component ]----------

  const CenteredCircularProgress = () => {
    return (
      <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}
        style={{ position: 'relative', left: '50%', top: '100%', transform: 'translate(-50%, 0%)',
        marginTop: PROGRESS_VERTICAL_SPACING, marginBottom: PROGRESS_VERTICAL_SPACING }} 
      >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }
      
  //  ----------[ Detail fetch component ]----------

  const fetchDetail = () => {
    if (hooks.open) {
      if (result.loading) {
        return (
          <CenteredCircularProgress />
        );
      } 
    
      if (result.error) {
        return (
          <Alert severity="error" style={{ marginTop: ALERT_TOP_SPACING, marginBottom: ALERT_BOTTOM_SPACING }}>
            {result.error.message}
          </Alert>
        );
      }
      if (result.value === undefined) {
        return (
          <Alert severity="error" style={{ marginTop: ALERT_TOP_SPACING, marginBottom: ALERT_BOTTOM_SPACING }}>
            Unknown Error
          </Alert>
        );
      }
  
      return isTabList() ? tabListBody(detailTabList) : detailBody(detailSections);
    }

    return (
      <div />
    );
  };

  //  ----------[ Create (empty) fetch component ]----------

  const createDetail = () => {
    if (hooks.open) {
      if (currentValues === undefined) {
        return (
          <CenteredCircularProgress />
        );
      } 

      return detailBody(detailSections);
    }

    return (
      <div />
    );
  }

  //  ----------[ Detail popup object ]----------

  return (
    <Dialog maxWidth="xl" PaperProps={{ ref: dialogRef, style: { minHeight: dialogHeight } }}
      open={hooks.open} 
      onClose={() => setHooks({ ...hooks, open: false })} 
      aria-labelledby="dialog-title"
      aria-describedby="dialog-message"
    >
      <DialogTitle id="dialog-title" style={{ padding: 0, margin: 0 }}>
        <CBSThemeHeader 
          title={detailTitle()}
          themeId={themeId} 
          size='small'
        />
      </DialogTitle>
      <DialogContent>
        {/* kkkkzzzz++++ */}
        {update >= 0 && isCreating() ? createDetail() : fetchDetail()}
        {/* kkkkzzzz++++ */}
        {/* {isCreating() ? createDetail() : fetchDetail()} */}
        {/* kkkkzzzz---- */}
        {saveError && <Alert severity="error">{saveError}</Alert>}
        {fieldError && <Alert severity="error">Some fields do not comply with the fill rules</Alert>}
      </DialogContent>
      <DialogActions>
        {((hooks.value !== undefined && isEditable()) || isCreating()) && saveButton()}
        {okCancelButton()}
      </DialogActions>
    </Dialog>
  );
};

