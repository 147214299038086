import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cbsIotcftoolPlugin = createPlugin({
  id: 'cbs-iotcftool',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsIotcftoolPage = cbsIotcftoolPlugin.provide(
  createRoutableExtension({
    name: 'CbsIotcftoolPage',
    component: () =>
      import('./components/IoTCFToolComponent').then(m => m.IoTCFToolComponent),
    mountPoint: rootRouteRef,
  }),
);