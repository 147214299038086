//  #########################
//  ##    CBS Logo Icon    ##
//  #########################

import React from 'react';
import { makeStyles } from '@material-ui/core';
import ChimeraLogoSmall from '../../images/ChimeraSmall.png';
import { SIDEBAR_LOGO_HEIGHT } from './CBSCustom';

//  ==========[ Styles ]==========

const useStyles = makeStyles({
  logo: {
    maxHeight: SIDEBAR_LOGO_HEIGHT,
  },
});

//  =================================
//            Object Caller
//  =================================

const CBSLogoIcon = () => {
  const classes = useStyles();
  return <img src={ChimeraLogoSmall} className={classes.logo} alt="Logo" />;
};

export default CBSLogoIcon;
