//  ##############################
//  ##    CBS Item Card Grid    ##
//  ##############################

import React from 'react';
import {createStyles, makeStyles, Theme, WithStyles} from '@material-ui/core/styles';

//  ==========[ Styles ]==========

const styles = (theme: Theme) => createStyles({
  root_min22: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
    gridAutoRows: '1fr',
    gridGap: theme.spacing(2),
  },
  root_min16: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(16em, 1fr))',
    gridAutoRows: '1fr',
    gridGap: theme.spacing(1)
  }
});

const useStyles = makeStyles(styles, { name: 'CBSItemCardGrid' });

const useRootStyle = (style: string | undefined) => {
  const classes = useStyles();
  
  switch(style)
  {
    case 'root_min16': 
      return classes.root_min16;
    default : 
      return classes.root_min22;
  }  
}

//  =================================
//            Object Caller
//  =================================

export type CBSItemCardGridProps = Partial<WithStyles<typeof styles>> & {
  style?: string;
  children?: React.ReactNode;
};

export function CBSItemCardGrid(props: CBSItemCardGridProps) {
  const { style, children, ...otherProps } = props;
  const selectedStyle = useRootStyle(style);
    
  return (
      <div className={selectedStyle} {...otherProps}>
      {children}
  </div>
  );
}
  