//  ################################
//  ##    CBS tables utilities    ##
//  ################################

import { useParams } from "react-router-dom";
import { EMPTY_FIELD } from "./common";
import { CBSTablePresets } from "./types";

//  ==========[ Identification column addition ]==========

export const cbsAddIdColumn = (array: any[], tag: string) => {
  let counter = 0;
  for (const file of array) {
    file.id = `${tag}-${++counter}`;
  }
}

//  ==========[ Router filter parameter ]==========

export const useParamsToFilter = (field: string[]): CBSTablePresets[] | undefined => {
  const param = useParams()['*'];

  if (param !== '') {
    const presets: CBSTablePresets[] = [];
    const values = param?.split('&');

    if (values !== undefined) {
      for (let index = 0; index < Math.min(field.length, values.length); index++) {
        presets.push({ key: 'filter', field: field[index], value: values[index] === EMPTY_FIELD ? '' : values[index] })
      }
    }

    return presets;
  }

  return undefined;
}

export const PAGE_SIZES = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 }
];

export const DEFAULT_PAGE_SIZE = 10;