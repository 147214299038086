//  ############################
//  ##    CBS tables types    ##
//  ############################

import { TableColumn } from "@backstage/core-components";

// Predefined control rules for fields in creation/update
export enum CBSDetailElementRules {
  VERIFY_URL = 1
};

// Query parametres for map functions
export type CBSQueryParams = {
  baseUrl: string, 
  token: string | undefined
};

// Expected result in response map functions
export type CBSFetchResult = {
  items: any[], 
  totalCount: number
};

// Parameters for fetch functions
export type CBSFetchParams = {
  url: string, 
  request?: RequestInit,
  preQueryFn?: (request: RequestInit | undefined, queryInfo: CBSQueryInfo) => void,
  postQueryFn?: (response: any, queryParams: CBSQueryParams) => Promise<CBSFetchResult>
};

// Dictionary (value, description) describing a generic item
export type CBSItemDictionary = Readonly<Record<"description" | "value", string>>;

// Route link path & arguments (field names or field values)
export type CBSLinkArguments = {
  path: string;
  fields: any[];
};

// Basic filter: reference field, title and parse function
export type CBSBasicFilterInfo = {
  field: string;
  title?: string;
  parseFn?: (value: string) => string;
};

// Combo box filter: reference field, combo box values and title
export type CBSComboFilterInfo = {
  field: string;
  values: readonly CBSItemDictionary[];
  title?: string;
};

// Multi value filter: reference field, values separators, hint, title and parse function
export type CBSMultiValueFilterInfo = {
  field: string;
  separators: RegExp;
  hint?: string;
  title?: string;
  parseFn?: (value: string) => string;
};

// Detail table properties ('scroll' is default)
export type CBSDetailTableProps = {
  // kkkkzzzz2----
  // type?: 'scroll' | 'paged';
  // maxHeight?: number;
  // kkkkzzzz2----
  columns: CBSTableColumn[];
  minWidth?: number;
  maxRows?: number;
  message?: string;
};

// Detail element (row) descriptor
export type CBSDetailElementProps = {
  name: string;
  value?: any;
  type?: 'string' | 'boolean' | 'numeric' | 'datetime' | 'divider' | 'radio';
  // Next fields will be used only for visualization
  table?: CBSDetailTableProps;
  link?: string | CBSLinkArguments;
  linkEnable?: (value: any) => boolean;
  // Next fields will be used only for editing and creation
  field?: string;
  disabled?: boolean;
  select?: readonly CBSItemDictionary[];
  required?: boolean;
  maxLength?: number;
  rule?: CBSDetailElementRules;
  // kkkkzzzz++++
  tag?: string;       // Tag identifying the field (usable by _getElementByTag) - If the element is '_radio', _createParamsFn will receive a _changed argument with tag-named field containing the value of the radio button selected
  hidden?: boolean;   // Element is hidden during rendering
  ignore?: boolean;   // Element is ignored when preparing the body of the creation/update request
  onChange?: (value: string, getElementByTag: (tag: string) => CBSDetailElementProps | undefined) => void;
  onUpdate?: (element: CBSDetailElementProps, record: any) => void;
  // kkkkzzzz++++
  // For internal use only
  id?: number;
  error?: number;
};

// Section descriptor (internal use)
export type DetailSectionProps = {
  title: string;
  elements: CBSDetailElementProps[];
};

// Tab list descriptor (internal use)
export type DetailTabListProps = {
  title: string;
  sections: DetailSectionProps[];
};

// Detail measures setting (internal use)
type DetailSettingsProps = {
  fieldNameWidth: number;
  fieldValueMinWidth?: number;
  fieldValueMaxWidth: number;
  detailMinHeight?: number;
};

// Detail group descriptor (internal use)
type DetailDetailProps = {
  title: string;
  function?: Function;
  detail?: DetailDetailProps[];
};

// Detail specification descriptor
export type CBSDetailProps = {
  title: string;
  settings: DetailSettingsProps;
  readParamsFn: (dictionary: any) => CBSFetchParams;
  readDetail: DetailDetailProps[];
  updateParamsFn?: (aggregate: any) => CBSFetchParams;
  updateDetail?: DetailDetailProps[];
  createParamsFn?: (aggregate: any, changed?: any) => CBSFetchParams;
  createDetail?: DetailDetailProps[];
};

// Detail authorization descriptor
export type CBSDetailAuths = {
  detail?: boolean;
  create?: boolean;
  update?: boolean;
  delete?: boolean;
};

// Table settings
export type CBSTableSettings = {
  maxSelection?: number;            // Max records read (and exportable to Excel). Default is 5000
  ormDialect?: 'dapper' | 'odata';  // Default is Dapper
  openFilters?: boolean;            // Default is false
  disableRowClick?: boolean;        // Disable opening details on row click. Default is false
};

// Table delegate functions
export type CBSTableDelegate = {
  filterConfirmCallback?: (confirmFn: () => void) => void;
};

// Table reference functions
export type CBSTableReference = {
  changeElementFn: (field: string, elementFn: (item: any) => JSX.Element) => void;
  reloadTable: () => void;
};

// Table preset values (filters and other in the future)
export type CBSTablePresets = {
  key: 'filter' | 'order';
  field: string;
  value?: string;
  direction?: 'asc' | 'desc';
};

// Basic column descriptor (derived from the Backstage _TableColumn interface)
export interface CBSTableColumn<T extends object = {}> extends TableColumn<T> {
  elementFn?: (value: any) => JSX.Element;
  link?: string | CBSLinkArguments;
  linkEnable?: (value: any) => boolean;
  // For internal use only
  nextSort?: string;
}

// Query filters for pre-query operations
export type CBSQueryInfoFilter = {
  field: string;
  values: string[];
  type: string;
}

// Query informations for pre-query operations
export type CBSQueryInfo = {
  page: {
    skip: number;
    top: number;
  },
  filters: CBSQueryInfoFilter[],
  order: {
    field: string;
    direction: string;
  } | undefined
};
