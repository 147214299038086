//  #########################
//  ##    CBS Constants    ##
//  #########################

//  ----------[ Environments ]----------

export const PROD_ENVIRONMENT: number = 0;
export const UAT_ENVIRONMENT: number = 1;
export const TEST_ENVIRONMENT: number = 2;

export const ENVIRONMENT_LIST = [
  { label: 'PROD', value: PROD_ENVIRONMENT.toString() },
  { label: 'UAT', value: UAT_ENVIRONMENT.toString() },
  { label: 'TEST', value: TEST_ENVIRONMENT.toString() }
];

//  ----------[ Whitelist Status ]----------

export const STATUS_WHITELISTED: number = 1;
export const STATUS_NOT_WHITELISTED: number = 0;

export const WHITELISTED_STATUS_LIST = [
  { label: 'WHITELISTED', value: STATUS_WHITELISTED.toString() },
  { label: 'NOT WHITELISTED', value: STATUS_NOT_WHITELISTED.toString() }
];

//  ----------[ Module Status Combo ]----------

export const ALL_MODULES: number = -1;
export const WHITELISTED_MODULES: number = 1;
export const NOT_WHITELISTED_MODULES: number = 0;

export const WHITELISTED_FILTER_LIST = [
  { label: 'ALL', value: ALL_MODULES.toString() },
  { label: 'WHITELISTED', value: WHITELISTED_MODULES.toString() },
  { label: 'NOT WHITELISTED', value: NOT_WHITELISTED_MODULES.toString() }
];

//  ----------[ Serialization Source Combo ]----------

export const SOURCE_ALL: number = -1;
export const SOURCE_SUPPLIER: number = 0;
export const SOURCE_DEVELOP: number = 1;
export const SOURCE_VIRTUAL: number = 2;

export const SOURCE_LIST = [
  { label: 'ALL', value: SOURCE_ALL.toString() },
  { label: 'SUPPLIER', value: SOURCE_SUPPLIER.toString() },
  { label: 'DEVELOP', value: SOURCE_DEVELOP.toString() },
  { label: 'VIRTUAL', value: SOURCE_VIRTUAL.toString() }
];

//  ----------[ Serialization Timestamp Combo ]----------

export const SERIALIZATION_1_DAY: number = 0;
export const SERIALIZATION_2_DAY: number = 1;
export const SERIALIZATION_7_DAY: number = 2;
export const SERIALIZATION_30_DAY: number = 3;

export const SERIALIZATION_LIST = [
  { label: 'LAST 1 DAY', value: SERIALIZATION_1_DAY.toString() },
  { label: 'LAST 2 DAYS', value: SERIALIZATION_2_DAY.toString() },
  { label: 'LAST 7 DAYS', value: SERIALIZATION_7_DAY.toString() },
  { label: 'LAST 30 DAYS', value: SERIALIZATION_30_DAY.toString() }
];

//  ----------[ Error Managing ]----------

export const UNITTYPE_NOT_FOUND: string = "W0001A";
export const CONFIGURATION_NOT_FOUND: string = "W0002A";
export const ERROR_WHILE_DISABLING: string = "E0006A";

//  ----------[ Operation Selection ]----------

export const LIST_ITEM_DISABLED: number = -2;
export const LIST_ITEM_UNSELECTED: number = -1;
export const LIST_ITEM_VALID: number = 0;

//  ----------[ Info Types ]----------

export const SHOW_VIEWER_INFO: number = 0;
export const SHOW_MOVER_INFO: number = 1;
export const SHOW_GATEKEEPER_INFO: number = 2;
export const SHOW_MOVER_RESULT: number = 3;
export const SHOW_GATEKEEPER_RESULT: number = 4;
export const SHOW_ERROR_INFO: number = 5;

//  ----------[ Severity Levels ]----------

export const SEVERITY_SUCCESS: number = 0;
export const SEVERITY_INFO: number = 1;
export const SEVERITY_WARNING: number = 2;
export const SEVERITY_ERROR: number = 3;

//  ----------[ Selections ]----------

export const UPD_NO_SETTINGS: number = 0;
export const UPD_LOCK_TABLE: number = 1;
export const UPD_SELECT_PAGE: number = 2;
export const UPD_UNSELECT_ALL: number = 4;
export const UPD_MODE_CHANGED: number = 8;

//  ----------[ Standard Sizes ]----------

export const SIZE_SELECT_STANDARD: number = 240;
export const SIZE_DIVIDER_STANDARD: number = 40;
export const SIZE_DIVIDER_REDUCED: number = 20;

//  ----------[ Standard Delays ]----------

export const TOOLTIP_STANDARD_DELAY: number = 500;

//  ----------[ Custom Colors ]----------

export const MEDIUM_GRAY_COLOR: string = "#BDBDBD";

