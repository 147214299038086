//  ##############################
//  ##    CBS tables commons    ##
//  ##############################

import { useRef } from "react";
import { CBSLinkArguments } from "./types";

//  ==========[ Constants ]==========

export const KEY_FIELD_ID: string = '@key_id';
export const EMPTY_FIELD: string = '@empty';

//  ==========[ Standardized error message composition ]==========

export const composeErrorMessage = (detectedProblems: any) => {
  let errorText: string = "";
  detectedProblems.forEach((p: any) => {
    errorText += `${errorText !== "" ? " - " : ""}${p.code}: ${p.message}`
  });
  return errorText;
}

//  ==========[ Dictionary functions ]==========

export const createDictionary = (dataSource: any, keys: string[]) => {
  const dictionary: any = {};
  for (const key of keys) {
    dictionary[key] = dataSource[key];
  }
  return dictionary;
}

//  ----------

export const findWithDictionary = (dataSource: any[], dictionary: any, keys: string[]) => {
  const index = dataSource.indexOf(dataSource.find((data) => {
    for (const key of keys) {
      if (data[KEY_FIELD_ID][key] !== dictionary[key]) {
        return false;
      }
    }
    return true;
  }));
  return index;
}

//  ----------

export const filterWithDictionary = (dictionary: any, keys: string[]) => {
  let filter = "";
  for (const key of keys) {
    filter += `${filter === "" ? "" : "&"}${key}=${dictionary[key]}`;
  }
  return filter;
}

//  ----------

export const idWithDictionary = (dictionary: any, keys: string[]) => {
  let id = "";
  for (const key of keys) {
    id += `${id === "" ? "" : "_"}${dictionary[key]}`;
  }
  return id;
}

//  ----------

export const titleWithDictionary = (dictionary: any, keys: string[]) => {
  let title = "";
  if (dictionary !== undefined){
    for (const key of keys) {
      title += `${title === "" ? "" : ", "}${dictionary[key]}`;
    }
  }
  return title;
}

//  ==========[ Argument compose functions ]==========

export const linkArgumentsCompose = (link: CBSLinkArguments, element?: any) => {
  let args = '';
  let value: string;
  link.fields.forEach((field) => {
    if (element !== undefined) {
      value = element[field] === undefined ? EMPTY_FIELD : element[field];
    }
    else {
      value = field === '' || field === undefined || field === null ? EMPTY_FIELD : field;
    }
    args += args === '' ? value : `&${value}`;
  });
  return `${link.path}/${args}`;
}

//  ==========[ Utility functions ]==========

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) {
    return widthRef.current;
  }

  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const width = outer.offsetWidth - inner.offsetWidth;  
  outer.parentNode?.removeChild(outer);

  didCompute.current = true;
  widthRef.current = width;

  return width;
};
