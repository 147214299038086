//  ##############################
//  ##    CBS Info Component    ##
//  ##############################

import React, { FC } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import * as TOOLKIT from '../utils/CBSToolkit';
import * as CONST from '../statics/CBSConstants';

//  ==========[ Types ]==========

export type CBSInfoMessage = {
  title: string;
  message: string;
};

//  =================================
//            Object Caller
//  =================================

interface CBSInfoComponentProps {
  text: string;
  tooltip?: string;
  message?: CBSInfoMessage;
  callback?: (info: CBSInfoMessage) => void;
}
  
export const CBSInfoComponent: FC<CBSInfoComponentProps> = ({ text, tooltip, message, callback }) => {

  //  ----------[ Hooks ]----------

  const classes = TOOLKIT.useColorStyles();

  //  ----------[ Components ]----------

  const iconButton = () => {
    return (
      <IconButton size='small' className={classes.textMoreSubtle} style={{ margin: 0, padding: 0, marginRight: 8 }}
        onClick={() => {
          callback!(message!);
        }}
      >
        <InfoIcon />
      </IconButton>
    );
  }

  //  ----------

  const infoButton = () => {
    if (tooltip) {
      return (
        <Tooltip title={tooltip!} arrow enterDelay={CONST.TOOLTIP_STANDARD_DELAY}>
          {iconButton()}
        </Tooltip>
      );  
    }
    return (
      <>
        {iconButton()}
      </>
    );
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={0}>
      {message && callback && infoButton()}
      <Typography className={classes.textMoreSubtle} variant="body2"> 
        {text}
      </Typography>
    </Grid>
  );
}