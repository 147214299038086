import React, { FC } from 'react';
import { Switch, Tooltip } from '@material-ui/core';
import * as CONST from '../statics/CBSConstants';

//  ==========[ Types ]==========

export type CBSSwitchCheckRef = {
  checked: boolean;
};

//  =================================
//            Object Caller
//  =================================

interface CBSSwitchProps {
  checked: CBSSwitchCheckRef | boolean;
  tooltip?: string;
  userData: any;
  callback: (checked: boolean, userData: any) => void;
}

export const CBSSwitch: FC<CBSSwitchProps> = ({ checked, tooltip, userData, callback }) => {

  //  ----------[ Hooks ]----------

  // The reason for the _CBSSwitchCheckRef object is that in some cases it needs a reference rather than a value.
  // If the switch is included in a table, updating the table will recreate the switch with the initial _checked value.
  // When using a reference, the table will recreate the switch with the referenced value
  const [isChecked, setChecked] = React.useState(typeof checked === 'object' ? checked.checked : checked);

  //  ----------[ Switch Object ]----------

  const baseSwitch = () => {
    return (
      <Switch size="small"
        checked={isChecked}
        onChange={(event: React.ChangeEvent<{ checked: unknown }>) => {
          setChecked(event.target.checked as boolean);
          callback(event.target.checked as boolean, userData);
        }}
      />
    );
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip!} arrow enterDelay={CONST.TOOLTIP_STANDARD_DELAY}>
        {baseSwitch()}
      </Tooltip>
    );  
  }
  return (
    <>
      {baseSwitch()}
    </>
  );
}