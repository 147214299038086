//  ##########################
//  ##    CBS PagingCard    ##
//  ##########################

import React, { FC, useRef } from 'react';
import { Box, Card, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from './utilities';

//  ==========[ Types ]==========

export type PagingCardInfo = {
  skip: number;
  top: number;
};

//  =================================
//            Object caller
//  =================================

interface CBSPagingCardProps {
  totalCount: number;
  pagingInfo: PagingCardInfo;
  pagingCallback: (pagingInfo: PagingCardInfo) => void;
}

export const CBSPagingCard: FC<CBSPagingCardProps> = ({ totalCount, pagingInfo, pagingCallback }) => {

  //  ----------[ Constants ]---------

  const ITEM_DIVIDER_LEN: number = 24;
  const PAGE_FIELD_LEN: number = 64;
  const PAGE_COMPONENT_HEIGHT: number = 26;

  const CALCULATE_PAGE_FLAG: string = "#";

  //  ----------[ Paging utilities ]----------

  const calculateRowsPerPage = () => {
    for (const elem of PAGE_SIZES) {
      if (elem.value === pagingInfo.top) {
        return pagingInfo.top;
      }
    }
    pagingInfo.top = DEFAULT_PAGE_SIZE;
    return pagingInfo.top; 
  }

  const calculateCurrentPage = () => {
    return (totalCount === 0 ? 0 : Math.ceil(pagingInfo.skip / pagingInfo.top) + 1).toString();
  }

  const calculateLastPage = () => {
    return Math.ceil(totalCount / pagingInfo.top);
  }

  //  ----------[ Hooks ]---------
  
  const [rowsPerPage, setRowsPerPage] = React.useState(calculateRowsPerPage());
  const [currentPage, setCurrentPage] = React.useState(CALCULATE_PAGE_FLAG);
  
  const textFieldRef = useRef<HTMLDivElement | undefined>();

  //  ----------[ Update functions ]----------

  const updatePaging = (skip: number) => {
    pagingInfo.skip = skip;

    setRowsPerPage(pagingInfo.top);
    setCurrentPage(CALCULATE_PAGE_FLAG + skip.toString());

    pagingCallback({ 
      skip: pagingInfo.skip, 
      top: pagingInfo.top 
    });
  }

  //  ----------[ Handlers ]----------

  const handlePageRowsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    pagingInfo.top = event.target.value as number
    updatePaging(0);
  };

  //  ----------

  const handlePageNumberChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentPage(event.target.value as string);
  };

  const handlePageNumberKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isNaN(Number(event.key))) {
      event.preventDefault();
    }
    if (event.key === 'Enter') {
      updatePaging((Math.min(Math.max(1, Number(currentPage)), calculateLastPage()) - 1) * pagingInfo.top);
      textFieldRef.current?.blur();
    }
  }

  //  ----------[ Components ]----------


  const titledTotal = () => {
    return (
      <>
        <Grid item>
          <Typography variant="subtitle2">Total:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{totalCount}</Typography>
        </Grid>      
      </>
    );
  };  

  const itemsDivider = (width: number) => {
    return (
      <Grid item>
        <Box sx={{ minWidth: width }} />
      </Grid>
    );
  };

  //  ----------

  const pageNumber = () => {
    return (
      <>
        <Grid item>
          <TextField inputRef={textFieldRef} id="page" variant="outlined" size="small" InputProps={{ style: { width: PAGE_FIELD_LEN, height: PAGE_COMPONENT_HEIGHT } }}
            inputProps={{ style: { textAlign: 'right' } }}
            disabled={totalCount <= pagingInfo.top}
            value={currentPage[0] === CALCULATE_PAGE_FLAG ? calculateCurrentPage() : currentPage}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => handlePageNumberChange(event)}
            onKeyUp={(event) => handlePageNumberKeyDown(event)}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">of</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{calculateLastPage()}</Typography>
        </Grid>
      </>          
    );
  };

  //  ----------

  const pageRows = () => {
    return (
      <>
        <Grid item>
          <Typography variant="subtitle2">Page Size:</Typography>
        </Grid>
        <Grid item>
          <Select variant="outlined" labelId="aria-label" id="rows-select" style={{ width: PAGE_FIELD_LEN, height: PAGE_COMPONENT_HEIGHT }} 
            value={rowsPerPage}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => handlePageRowsChange(event)}
          >
            {PAGE_SIZES.map(option => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </>          
    );
  };

  //  ----------[ Buttons ]----------

  const firstPageButton = () => {
    return (
      <Grid item>
        <IconButton children={<FirstPageIcon />}
          color="primary" size="small" style={{ height: PAGE_COMPONENT_HEIGHT }}
          disabled={pagingInfo.skip <= 0}
          onClick={() => updatePaging(0)}
        />
      </Grid>
    );
  };

  //  ----------

  const previousPageButton = () => {
    return (
      <Grid item>
        <IconButton children={<ArrowLeftIcon />}
          color="primary" size="small" style={{ height: PAGE_COMPONENT_HEIGHT }}
          disabled={pagingInfo.skip <= 0}
          onClick={() => updatePaging(Math.max(0, pagingInfo.skip - pagingInfo.top))}
        />
      </Grid>
    );
  };

  //  ----------

  const nextPageButton = () => {
    return (
      <Grid item>
        <IconButton children={<ArrowRightIcon />}
          color="primary" size="small" style={{ height: PAGE_COMPONENT_HEIGHT }}
          disabled={pagingInfo.skip + pagingInfo.top >= totalCount}
          onClick={() => updatePaging(Math.min(totalCount - 1, pagingInfo.skip + pagingInfo.top))}
        />
      </Grid>
    );
  };

  //  ----------

  const lastPageButton = () => {
    return (
      <Grid item>
        <IconButton children={<LastPageIcon />}
          color="primary" size="small" style={{ height: PAGE_COMPONENT_HEIGHT }}
          disabled={pagingInfo.skip + pagingInfo.top >= totalCount}
          onClick={() => {
            const skip = Math.floor(totalCount / pagingInfo.top) * pagingInfo.top;
            updatePaging(skip === totalCount ? skip - pagingInfo.top : skip);
          }}
        />
      </Grid>
    );
  };

  //  ----------[ Paging card object ]----------

  return (
    <Card elevation={2} style={{ width: '100%'}}>
      <Grid container spacing={1} justifyContent="flex-end" direction="row" alignItems="center" style={{ padding: "6px 16px 6px 16px"}}>
        {titledTotal()}
        {itemsDivider(ITEM_DIVIDER_LEN)}
        {pageRows()}
        {itemsDivider(ITEM_DIVIDER_LEN)}
        {firstPageButton()}
        {previousPageButton()}
        {pageNumber()}            
        {nextPageButton()}
        {lastPageButton()}
      </Grid>
    </Card>
  );
}
