import React from 'react';
import { NavLink } from 'react-router-dom';
// Tag ADMINGROUP
// import { configApiRef, useApi } from '@backstage/core-plugin-api';
// import { UserSettingsSignInAvatar, useUserProfile } from '@backstage/plugin-user-settings';
import { UserSettingsSignInAvatar } from '@backstage/plugin-user-settings';
import { Sidebar, sidebarConfig, SidebarDivider, SidebarGroup, SidebarItem, SidebarPage, SidebarSpace, useSidebarOpenState } from '@backstage/core-components';
import { Link, makeStyles, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import WorkIcon from '@material-ui/icons/Work';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import DomainIcon from '@material-ui/icons/Domain';
import DescriptionIcon from '@material-ui/icons/Description';
// Tag ADMINGROUP
// import { cbsEnableToolsGroups, CONST, TOOLKIT, useChimeraEnvironment } from '@internal/plugin-cbs-core';
import { CONST, TOOLKIT, useChimeraEnvironment } from '@internal/plugin-cbs-core';
import CBSLogoFull from './CBSLogoFull';
import CBSLogoIcon from './CBSLogoIcon';
import * as CBS from './CBSCustom';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

//  ==========[ Styles ]==========

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: CBS.SIDEBAR_LOGO_HEIGHT + 2 * CBS.SIDEBAR_LOGO_SPACE,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: CBS.SIDEBAR_MARGIN_LEFT,
  },
  linkClosed: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: CBS.SIDEBAR_MARGIN_LEFT_CLOSED,
  },
});

//  ==========[ Sidebar Logo Object ]==========

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  const config = useApi(configApiRef);   
  const version = `${config.getOptionalString('cbsChimeraSettings.version')}`;

  return (
    <>
      <div className={classes.root}>
        <Link
          component={NavLink}
          to="/"
          underline="none"
          className={isOpen ? classes.link : classes.linkClosed}
          aria-label="Home"
        >
          {isOpen ? <CBSLogoFull /> : <CBSLogoIcon />}
        </Link>
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: isOpen ? -10 : -76, marginBottom: isOpen ? 10 : 76 }}>
        <Typography variant="caption" style={{ color: '#808080' }}>
          {isOpen ? `Version ${version}` : `${version}`}
        </Typography>
      </div>
    </>
  );
};

//  ==========[ Sidebar Items Object ]==========

const SidebarItems = () => {
  const environment = useChimeraEnvironment();

  const items: JSX.Element[] = [];

  items.push(<SidebarItem key='1' icon={DomainIcon} to="cbs-dams" text="DAMS" />);

  if (environment !== CONST.TEST_ENVIRONMENT) {
    items.push(<SidebarItem key='2' icon={BuildIcon} to="cbs-crew" text="CrEW" />);
  }

  if (TOOLKIT.useLocalDevelopment()) {
    items.push(<SidebarItem key='3' icon={DescriptionIcon} to="cbs-iotcftool" text="IoT-CF Tool" />);
  }

  return (
    <>
      {items}
    </>
  );
}

//  ==========[ Root Object ]==========

interface RootProps {
  children: React.ReactNode;
};

export const Root = ({ children }: RootProps) => {
  CBS.rootCustomizations();

  // Tag ADMINGROUP
  // const config = useApi(configApiRef);   
  // const { backstageIdentity } = useUserProfile();
  
  return (
    <SidebarPage>
      <Sidebar disableExpandOnHover>
        <SidebarLogo />
        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          <SidebarItems />
          {/* End global nav */}
        </SidebarGroup>
        <SidebarSpace />
        {/* Tag ADMINGROUP */}
        {/* {cbsEnableToolsGroups(config, backstageIdentity) && <> */}
        {TOOLKIT.useLocalDevelopment() && <>
          <SidebarDivider />
          <SidebarGroup label="Tools" icon={<WorkIcon />}>
            <SidebarItem icon={WorkIcon} to="/cbs-tools" text="Tools" />
          </SidebarGroup>
        </>}
        <SidebarDivider />
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/cbs-settings">
          <SidebarItem icon={SettingsIcon} to="/cbs-settings" text="Settings" />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};

