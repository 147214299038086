//  ################################
//  ##    CBS Segmented Button    ##
//  ################################

import React, { FC } from 'react';
import { Button, ButtonGroup, Tooltip } from '@material-ui/core';

import * as CONST from '../statics/CBSConstants';

//  ==========[ Constants ]==========

const UNSELECTED_SEGMENT: number = -1;

//  ==========[ Enums ]==========

export enum CBSSegmentedButtonSizes {
  STANDARD = 0,
  TINY,
  SMALL,
  MEDIUM,
  LARGE
}

//  ==========[ Types ]==========

export type CBSSegmentedButtonOptions = {
  nullable?: boolean;
  disabled?: boolean;
};

export type CBSSegmentedButtonSegment = {
  caption: string;
  value: any;
  tooltip: string;
};

export type CBSSegmentedButtonSelectRef = {
  selected: any;
};

//  =================================
//            Object Caller
//  =================================

interface CBSSegmentedButtonProps {
  segments: CBSSegmentedButtonSegment[];
  selected?: any;
  selectedRef?: CBSSegmentedButtonSelectRef;
  options: CBSSegmentedButtonOptions;
  widthPx: number;
  size: CBSSegmentedButtonSizes;
  userData: any;
  callback: (value: any, userData: any) => void;
}

export const CBSSegmentedButton: FC<CBSSegmentedButtonProps> = ({ segments, selected, selectedRef, options, widthPx, size, userData, callback }) => {

  //  ----------[ Utility Methods ]----------

  const setSelected = () => {
    if (selected !== undefined) {
      for (let index = 0; index < segments.length; index++) {
        if (segments[index].value === selected) {
          return index;
        }
      }
    }
    else if (selectedRef?.selected !== undefined) {
      for (let index = 0; index < segments.length; index++) {
        if (segments[index].value === selectedRef?.selected) {
          return index;
        }
      }
    }
    return UNSELECTED_SEGMENT;
  }

  //  ----------[ Hooks ]----------

  const [activeSegment, setActiveSegment] = React.useState(setSelected());

  //  ----------[ Utility functions ]----------

  const setSize = () => {
    if (size === CBSSegmentedButtonSizes.SMALL) {
      return "small";
    }
    else if (size === CBSSegmentedButtonSizes.MEDIUM) {
      return "medium";
    }
    else if (size === CBSSegmentedButtonSizes.LARGE) {
      return "large";
    }

    return undefined;
  };

  //  ----------[ Components ]----------

  const baseUnselectedButton = (index: number, style: React.CSSProperties) => {
    return (
      <Button key={index.toString()} style={style} variant="outlined" color="primary"
        onClick={() =>  {
          setActiveSegment(index);
          callback(segments[index].value, userData);
        }}
      >
        {segments[index].caption}
      </Button>
    );
  }

  const unselectedButton = (index: number, style: React.CSSProperties) => {
    return (
      options.disabled ?
        baseUnselectedButton(index, style)
    :
      // If I use _CBSTooltip, for some reason the segmented button is no longer continuous and appears as a series of discrete buttons
      <Tooltip key={index.toString()} title={segments[index].tooltip} arrow enterDelay={CONST.TOOLTIP_STANDARD_DELAY}>
        {baseUnselectedButton(index, style)}
      </Tooltip>

    );
  }

  //  --------------------

  const baseSelectedButton = (index: number, style: React.CSSProperties) => {
    return (
      <Button key={index.toString()} style={style} variant={activeSegment === index ? "contained" : "outlined"} color="primary"
        onClick={() => {
          if (options.nullable && activeSegment === index) {
            setActiveSegment(UNSELECTED_SEGMENT);
            callback(undefined, userData);
          }
          else if (activeSegment !== index) {
            setActiveSegment(index);
            callback(segments[index].value, userData);
          }
        }}
      >
        {segments[index].caption}
      </Button>
    );
  }

  const selectedButton = (index: number, style: React.CSSProperties) => {
    return (
      options.disabled ?
      baseSelectedButton(index, style)
    :
      // If I use _CBSTooltip, for some reason the segmented button is no longer continuous and appears as a series of discrete buttons
      <Tooltip key={index.toString()} title={segments[index].tooltip} arrow enterDelay={CONST.TOOLTIP_STANDARD_DELAY}>
        {baseSelectedButton(index, style)}
      </Tooltip>
    );
  }

  //  --------------------

  const segment = (index: number) => {
    const minWidth = `${widthPx.toString()}px`
    const cssStyle: React.CSSProperties = size === CBSSegmentedButtonSizes.TINY ?
      {fontSize: 12, minWidth: minWidth, maxHeight: '24px', paddingBottom: 4}
    :
      {minWidth: minWidth, maxHeight: '1000px'};

    return (
      activeSegment === UNSELECTED_SEGMENT ?
        unselectedButton(index, cssStyle)
      :
        selectedButton(index, cssStyle)
    );
  }

  //  ----------[ Segments Assembly ]----------

  const segmentArray: JSX.Element[] = [];
  let index = 0;
  segments.forEach(() => {
    segmentArray.push(segment(index++));
  });

  //  ----------[ Button Group Object ]----------

  return (
    <ButtonGroup variant="outlined" aria-label="button-group" disabled={options.disabled} size={setSize()}>
      {segmentArray}
    </ButtonGroup>
  );
};
