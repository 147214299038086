//  #############################
//  ##    CBS tabbed layout    ##
//  #############################

import { attachComponentData } from '@backstage/core-plugin-api';
import React, { Children, Fragment, isValidElement, PropsWithChildren, ReactNode } from 'react';
import { CBSRoutedTabs } from './CBSRoutedTabs';
import { TabProps } from '@material-ui/core/Tab';

type SubRoute = {
  path: string;
  title: string;
  select?: boolean;
  children: JSX.Element;
  tabProps?: TabProps<React.ElementType, { component?: React.ElementType }>;
};

const Route: (props: SubRoute) => null = () => null;

// This causes all mount points that are discovered within this route to use the path of the route itself
attachComponentData(Route, 'core.gatherMountPoints', true);

function createSubRoutesFromChildren(childrenProps: ReactNode): SubRoute[] {
  // Directly comparing child.type with Route will not work with in
  // combination with react-hot-loader in storybook
  // https://github.com/gaearon/react-hot-loader/issues/304
  const routeType = (
    <Route path="" title="">
      <div />
    </Route>
  ).type;

  return Children.toArray(childrenProps).flatMap(child => {
    if (!isValidElement(child)) {
      return [];
    }

    if (child.type === Fragment) {
      return createSubRoutesFromChildren(child.props.children);
    }

    if (child.type !== routeType) {
      throw new Error('Child of TabbedLayout must be an TabbedLayout.Route');
    }

    const { path, title, select, children, tabProps } = child.props;
    return [{ path, title, select, children, tabProps }];
  });
}

/**
 * Prop types for the CBSTabbedLayout component.
 * @public
 */
export type CBSTabbedLayoutProps = {
  select?: number;
  children: ReactNode;
};

/**
 * CBSTabbedLayout is a compound component, which allows you to define a layout for
 * pages using a sub-navigation mechanism.
 *
 * @remarks
 * Consists of two parts: CBSTabbedLayout and CBSTabbedLayout.Route
 *
 * @example
 * ```jsx
 * <CBSTabbedLayout>
 *   <CBSTabbedLayout.Route path="/example" title="Example tab">
 *     <div>This is rendered under /example/anything-here route</div>
 *   </CBSTabbedLayout.Route>
 * </CBSTabbedLayout>
 * ```
 */
export function CBSTabbedLayout(props: PropsWithChildren<CBSTabbedLayoutProps>) {
  const routes = createSubRoutesFromChildren(props.children);
  return <CBSRoutedTabs routes={routes} select={props.select} />;
}

CBSTabbedLayout.Route = Route;
