import { 
  microsoftCrewInfo, microsoftPublicCrewInfo,
  microsoftDamsInfo, microsoftPublicDamsInfo,
  microsoftIotcftoolInfo,
} from 'backstage-plugin-cbs-common';
import { 
  microsoftCrewAuthApiRef, microsoftPublicCrewAuthApiRef, 
  microsoftDamsAuthApiRef, microsoftPublicDamsAuthApiRef, 
  microsoftIotcftoolAuthApiRef,
} from '../components/apis/MicrosoftTokenAuthApis';

/*
 * Add here the new providers - Search tag: [NEW_TOKEN_PROVIDER] - #4
 */
export const crewProvider = {
  id: microsoftCrewInfo.id,
  title: microsoftCrewInfo.title,
  message: microsoftCrewInfo.message,
  apiRef: microsoftCrewAuthApiRef
};
export const crewPublicProvider = {
  id: microsoftPublicCrewInfo.id,
  title: microsoftPublicCrewInfo.title,
  message: microsoftPublicCrewInfo.message,
  apiRef: microsoftPublicCrewAuthApiRef
};

export const damsProvider = {
  id: microsoftDamsInfo.id,
  title: microsoftDamsInfo.title,
  message: microsoftDamsInfo.message,
  apiRef: microsoftDamsAuthApiRef
};
export const damsPublicProvider = {
  id: microsoftPublicDamsInfo.id,
  title: microsoftPublicDamsInfo.title,
  message: microsoftPublicDamsInfo.message,
  apiRef: microsoftPublicDamsAuthApiRef
};

export const iotcftoolProvider = {
  id: microsoftIotcftoolInfo.id,
  title: microsoftIotcftoolInfo.title,
  message: microsoftIotcftoolInfo.message,
  apiRef: microsoftIotcftoolAuthApiRef
};
/*
 * ----------
 */


