import React, { useEffect } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { Route } from 'react-router';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { orgPlugin } from '@backstage/plugin-org';
import { alertApiRef, identityApiRef, microsoftAuthApiRef, ProfileInfo, useApi, configApiRef, BackstageIdentityResponse, BackstageIdentityApi, OAuthApi, OpenIdConnectApi, ProfileInfoApi, SessionApi } from '@backstage/core-plugin-api';
import { Config } from '@backstage/config';
import { datadogRum } from '@datadog/browser-rum';
import { apis } from './apis';
import { Root } from './components/Root';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { isInternalProvider, isLocalDevelopment } from 'backstage-plugin-cbs-common';
import { HomePage } from './components/home/HomePage';
import { chimeraProvider } from './CBSIdentityProviders';
import { SignInPage } from './CBSSignInPage';
import { chimeraPublicProvider, microsoftPublicAuthApiRef } from '@internal/plugin-cbs-auth';
import { CbsSettingsPage } from '@internal/plugin-cbs-settings';
import { CbsCrewPage } from '@internal/plugin-cbs-crew';
import { CbsDamsPage } from '@internal/plugin-cbs-dams';
import { CbsIotcftoolPage } from '@internal/plugin-cbs-iotcftool';
import { CbsToolsPage } from '@internal/plugin-cbs-tools';
// Tag ADMINGROUP
// import { cbsEnableToolsGroups } from '@internal/plugin-cbs-core';

const app = createApp({
  apis,
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          providers={{
            default: chimeraProvider, 
            public: chimeraPublicProvider
          }}
          title="Select a sign-in method"
          align="center"
        />
      );
    },
  },
  
  bindRoutes({ bind }) {
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();


const App = () => {

  //  ----------[ Hooks ]----------

  const [profile, setProfile] = React.useState<ProfileInfo | undefined>(undefined);
  const [initDdRum, setInitDdRum] = React.useState(true);
  const [localDevelopment, setLocalDevelopment] = React.useState(false);
  // Tag ADMINGROUP
  // const [cbsTools, setCbsTools] = React.useState(false);

  //  ----------[ Datadog utility methods ]----------

  const initDatadogRUM = (config: Config) => {
    if (initDdRum) {
      if (config.getString('app.datadogRum.service') !== 'OFF') {
        datadogRum.init({
          applicationId: config.getString('app.datadogRum.applicationId'),
          clientToken: config.getString('app.datadogRum.clientToken'),
          site: config.getString('app.datadogRum.site'),
          service: config.getString('app.datadogRum.service'),
          env: config.getString('cbsChimeraSettings.environment').toLowerCase(),
          version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: Number(config.getString('app.datadogRum.replayRate')), 
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: true,
          defaultPrivacyLevel: 'mask-user-input'
        });
      }

      setInitDdRum(false);
    }
  }

  //  ----------

  const setDatadogUser = async (config: Config, providerApi: OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi) => {
    if (config.getString('app.datadogRum.service') !== 'OFF' && config.getString('app.datadogRum.traceUser') === 'true') {
      // Implemented as a temporary solution to allow user identification in Datadog.
      // The urgency of user identification seems to have been overcome, so this function will probably be removed soon
      const idToken = await providerApi.getIdToken();
      const [_header, payload, _signature] = idToken.split('.');
      const userId = JSON.parse(Buffer.from(payload, 'base64').toString()).oid as string;

      datadogRum.setUser({
        id: userId,
        name: userId,
        email: userId
      });
    }
  }

  //  ----------[ User Profile Provider ]----------

  interface UserProfileProviderProps {
    children: React.ReactNode;
  };
  
  const UserProfileProvider = ({ children }: UserProfileProviderProps) => {
    const microsoftApi = useApi(isInternalProvider() ? microsoftAuthApiRef : microsoftPublicAuthApiRef);
    const identityApi = useApi(identityApiRef);
    const alertApi = useApi(alertApiRef);  
    const config = useApi(configApiRef);   

    // Tag ADMINGROUP
    // const identity = useAsync(async (): Promise<any> => {
    //   return {
    //     pro: await identityApi.getProfileInfo(),
    //     ide: await identityApi.getBackstageIdentity()
    //   };
    const identity = useAsync(async (): Promise<ProfileInfo> => {
      return await identityApi.getProfileInfo();
    }, []);

    const ownership = useAsync(async (): Promise<BackstageIdentityResponse | undefined> => {
      return await microsoftApi.getBackstageIdentity();
    }, []);

    useEffect(() => {
      initDatadogRUM(config);
      setLocalDevelopment(isLocalDevelopment(config.getString('app.baseUrl')));
    }, [config]);

    useEffect(() => {
      if (identity.error) {
        alertApi.post({
          message: `Failed to load user identity: ${identity.error}`,
          severity: 'error',
        });
        setProfile({});
      }
      // Tag ADMINGROUP
      // else if (identity.value?.pro) {
      //   setProfile(identity.value.pro);
      //   setCbsTools(cbsEnableToolsGroups(config, identity.value.ide));
      else if (identity.value) {
        setProfile(identity.value);
      }
      if (ownership.error) {
        alertApi.post({
          message: `Failed to load user groups: ${ownership.error}`,
          severity: 'error',
        });
      }
      else if (ownership.value?.identity) {
        setDatadogUser(config, microsoftApi);
      }  
    }, [identity.error, identity.value, ownership.error, ownership.value, microsoftApi, alertApi, config]);

    return (
      <>
        {children}
      </>
    );
  }

  //  ----------[ App Provider ]----------

  return (
    <AppProvider>
      <AlertDisplay />
      <OAuthRequestDialog />
      <AppRouter>
        <UserProfileProvider>
          <Root>
            <FlatRoutes>
              <Route path="/" element={<HomepageCompositionRoot />}>
                <HomePage profile={profile}/>
              </Route>
              <Route path="/cbs-settings" element={<CbsSettingsPage />} />
              <Route path="/cbs-crew" element={<CbsCrewPage />} />
              <Route path="/cbs-dams" element={<CbsDamsPage />} />
              {/* Tag ADMINGROUP */}
              {/* {cbsTools && <Route path="/cbs-tools" element={<CbsToolsPage />} />} */}
              {localDevelopment && <Route path="/cbs-tools" element={<CbsToolsPage />} />}
              {localDevelopment && <Route path="/cbs-iotcftool" element={<CbsIotcftoolPage />} />}
            </FlatRoutes>
          </Root>
        </UserProfileProvider>
      </AppRouter>
    </AppProvider>
  );
}

export default App;

