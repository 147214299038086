import React, { useEffect, useState } from 'react';
import { Content, Header, Progress, SignInProviderConfig } from "@backstage/core-components";
import { BackstageIdentityResponse, useApi } from "@backstage/core-plugin-api";
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { TOOLKIT } from '@internal/plugin-cbs-core';

const SHOW_LOGIN_PAGE: number = 1;
const SHOW_PROGRESS: number = 2;
const SIGN_IN_SUCCESS : number = 3;

interface CBSTokenLoginProps {
  children: React.ReactNode;
  provider: SignInProviderConfig;
  pluginTitle: string;
  pluginSubtitle: string;
  pluginIcon: JSX.Element;
};
  
export const CBSTokenLogin = ({ children, provider, pluginTitle, pluginSubtitle, pluginIcon }: CBSTokenLoginProps) => {
  const authApi = useApi(provider.apiRef);
  const classes = TOOLKIT.useColorStyles();

  const [error, setError] = useState<Error>();

  // The SignIn component takes some time to decide whether the user is logged-in or not.
  // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
  // displayed for a split second when the user is already logged-in.
  const [status, setStatus] = useState<number>(0);

  type LoginOpts = { 
    checkExisting?: boolean; 
    showPopup?: boolean
  };
  
  const login = async ({ checkExisting, showPopup }: LoginOpts) => {

    try {
      let identityResponse: BackstageIdentityResponse | undefined;

      if (checkExisting) {
        // Do an initial check if any logged-in session exists
        identityResponse = await authApi.getBackstageIdentity({ optional: true });
      }

      // If no session exists, show the sign-in page
      if (!identityResponse && showPopup) {
        // When user intentionally clicks the Sign In button, autoShowPopup is set to true
        setStatus(SHOW_LOGIN_PAGE);
        identityResponse = await authApi.getBackstageIdentity({
          instantPopup: true,
        });
        if (!identityResponse) {
          throw new Error(
            `The ${provider.title} provider is not configured to support user consent`,
          );
        }
      }

      if (!identityResponse) {
        setStatus(SHOW_LOGIN_PAGE);
        return;
      }

      await authApi.getProfile();
      setStatus(SIGN_IN_SUCCESS);
      } 
    catch (err: any) {
      // User closed the sign-in modal
      setError(err);
      setStatus(SHOW_PROGRESS);
    }
  };

  useEffect(() => {
    login({ checkExisting: true });
  });

  return (
    <>
      {status !== SIGN_IN_SUCCESS ? 
        <>
          <Header title={pluginTitle} subtitle={pluginSubtitle} />
          <Content>
            <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}
              style={{ position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
            >
              <Grid item>
                <Box display="flex" flexDirection="row" className={classes.textSubtle}>
                  {pluginIcon}
                  <Typography variant="subtitle1" style={{fontWeight: 'bold', marginLeft: 6, marginRight: 30}}>
                    {pluginTitle}
                  </Typography>
                </Box>
              </Grid>
              {status === SHOW_LOGIN_PAGE ? 
                <Grid item>
                  <Button color="primary" variant="outlined" style={{ marginTop: 10 }}
                    onClick={() => login({ checkExisting: false, showPopup: true })}
                  >
                    VERIFY AUTHORIZATION
                  </Button>
                </Grid>
              :
                <>
                  <Grid item>
                    <Typography variant="body2" style={{paddingBottom: 5}}>
                      Verifying authorization
                    </Typography>                    
                  </Grid>
                  <Grid item>
                    <Box sx={{minWidth: 240}}>
                      <Progress />
                    </Box>
                  </Grid>
                </>
              }
              {error && error.name !== 'PopupRejectedError' && 
                <Grid item>
                  <Typography variant="body1" color="error" style={{ marginTop: 20 }}>
                    {error.message}
                  </Typography>
                </Grid>
              }
            </Grid>
          </Content>
        </>
      :
        <>
          {children}
        </>
      }
    </>
  );
}
