//  #########################
//  ##    CBS User Menu    ##
//  #########################

import React from 'react';
import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import { identityApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import SignOutIcon from '@material-ui/icons/MeetingRoom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { microsoftCrewAuthApiRef, microsoftPublicCrewAuthApiRef, microsoftDamsAuthApiRef, microsoftPublicDamsAuthApiRef } from '@internal/plugin-cbs-auth';
import { isInternalProvider } from 'backstage-plugin-cbs-common';

//  ==========[ Styles ]==========

const useStyles = makeStyles<Theme>(
  theme => ({
    whiteButton: {
      color: theme.palette.common.white,
    },
    autoButton: {
    },
  }),
  { name: 'ChimeraUserMenu' },
);

//  =================================
//            Object Caller
//  =================================

interface CBSUserMenuProps {
  whiteMenu: boolean;
};

export const CBSUserMenu = ({ whiteMenu }: CBSUserMenuProps) => {
  const classes = useStyles();

  //  ----------[ Hooks ]----------

  const errorApi = useApi(errorApiRef);
  const identityApi = useApi(identityApiRef);

  const microsoftDamsAuthApi = useApi(isInternalProvider() ? microsoftDamsAuthApiRef : microsoftPublicDamsAuthApiRef);
  const microsoftCrewAuthApi = useApi(isInternalProvider() ? microsoftCrewAuthApiRef : microsoftPublicCrewAuthApiRef);
  
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  //  ----------[ Handlers ]----------

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setOpen(false);
  };
  
  //  ----------[ User Menu Object ]----------

  return (
    <>
      <IconButton data-testid="user-menu" aria-label="more" className={whiteMenu ? classes.whiteButton : classes.autoButton}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} 
        onClose={handleClose}
      >
        <MenuItem data-testid="sign-out"
          onClick={() => {
            identityApi.signOut().catch(error => errorApi.post(error));
            microsoftCrewAuthApi.signOut().catch(error => errorApi.post(error));
            microsoftDamsAuthApi.signOut().catch(error => errorApi.post(error));
          }}
        >
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};
