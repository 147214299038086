/**
 * Common functionalities for the cbs plugin.
 *
 * @packageDocumentation
 */

/**
 * Unified base provider information
 */
export type BaseProviderInfo = {
  id: string;
  providerId: string;
  apiRefId: string;
  title: string;
};

/**
 * Unified token provider information
 */
 export type TokenProviderInfo = {
  id: string;
  providerId: string;
  apiRefId: string;
  email: string;
  title: string;
  message: string;
};

export const microsoftPublicInfo: BaseProviderInfo = {
  id: 'microsoftpublic-auth-provider',
  providerId: 'microsoftpublic',
  apiRefId: 'core.auth.microsoftpublic',
  title: 'Microsoft Public Provider',
};

/*
 * Add here the new provider information - Search tag: [NEW_TOKEN_PROVIDER] - #1
 */
export const microsoftCrewInfo: TokenProviderInfo = {
  id: 'microsoftcrew-auth-provider',
  providerId: 'microsoftcrew',
  apiRefId: 'core.auth.microsoftcrew',
  email: 'microsoftcrew.provider@electrolux.com',
  title: 'Microsoft CrEW Token Provider',
  message: 'User Authorization using Microsoft Azure AD',
};
export const microsoftPublicCrewInfo: TokenProviderInfo = {
  id: 'microsoftpubliccrew-auth-provider',
  providerId: 'microsoftpubliccrew',
  apiRefId: 'core.auth.microsoftpubliccrew',
  email: 'microsoftpubliccrew.provider@electrolux.com',
  title: 'Microsoft CrEW Public Token Provider',
  message: 'User Authorization using Microsoft Azure AD',
};

export const microsoftDamsInfo: TokenProviderInfo = {
  id: 'microsoftdams-auth-provider',
  providerId: 'microsoftdams',
  apiRefId: 'core.auth.microsoftdams',
  email: 'microsoftdams.provider@electrolux.com',
  title: 'Microsoft DAMS Token Provider',
  message: 'User Authorization using Microsoft Azure AD',
};
export const microsoftPublicDamsInfo: TokenProviderInfo = {
  id: 'microsoftpublicdams-auth-provider',
  providerId: 'microsoftpublicdams',
  apiRefId: 'core.auth.microsoftpublicdams',
  email: 'microsoftpublicdams.provider@electrolux.com',
  title: 'Microsoft DAMS Public Token Provider',
  message: 'User Authorization using Microsoft Azure AD',
};

export const microsoftIotcftoolInfo: TokenProviderInfo = {
  id: 'microsoftiotcftool-auth-provider',
  providerId: 'microsoftiotcftool',
  apiRefId: 'core.auth.microsoftiotcftool',
  email: 'microsoftiotcftool.provider@electrolux.com',
  title: 'Microsoft IoTCFTool Token Provider',
  message: 'User Authorization using Microsoft Azure AD',
};
/*
 * ----------
 */

export const getCspNonce = () => {
  const meta = document.getElementsByTagName('meta');
  for (let i = 0; i < meta.length; i++) {
    if (meta[i].getAttribute("property") === "csp-nonce") {
      return meta[i].getAttribute("content") ?? undefined;
    }
  }
  return undefined;
}

export const isLocalDevelopment = (baseUrl: string): boolean => {
  return baseUrl.indexOf('localhost') !== -1;
}

export const isInternalProvider = (): boolean => {
  return new URL(window.location.href).hostname.includes('.int.');
}
