//  ############################
//  ##    CBS Theme Header    ##
//  ############################

import React, { FC, ReactNode } from 'react';
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";

//  ==========[ Constants ]==========

const SIDE_SPACING: number = 24;

//  =================================
//            Object Caller
//  =================================

interface CBSThemeHeaderArgs {
  title: ReactNode | string;
  themeId: string; 
  size?: "small" | "standard";
}
    
export const CBSThemeHeader: FC<CBSThemeHeaderArgs> = ({ title, themeId, size }) => {

  //  ----------[ Styles ]----------

  const styles = (theme: Theme) => createStyles({
    root: {
      color: theme.palette.common.white,
      padding: size === "small" ? theme.spacing(2, 2, 2) : theme.spacing(3, 2, 3),
      backgroundImage: theme.getPageTheme({ themeId: themeId }).backgroundImage,
      backgroundPosition: 0,
      backgroundSize: 'inherit',
    },
  });

  //  ----------[ Hooks ]----------
  
  const classes = makeStyles(styles, { name: 'ChimeraThemeHeader' })();

  //  ----------[ Dialog Header Object ]----------

  return (
    <div className={classes.root}>
      <Grid container direction="row" alignItems="center">
        <Box sx={{ minWidth: SIDE_SPACING }} />
        <Grid item>
          {title}
        </Grid>
        <Box sx={{ minWidth: SIDE_SPACING }} />
      </Grid>
    </div>
  );
}
