//  #############################
//  ##    CBS Select Button    ##
//  #############################

import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';

//  =================================
//            Object Caller
//  =================================

interface CBSSelectButtonProps {
  title: string;
  widthPx: number;
  values: any[];
  selected: any;
  userData: any;
  callback: (value: any, userData: any) => void;
}
  
export const CBSSelectButton: FC<CBSSelectButtonProps> = ({ title, widthPx, values, selected, userData, callback }) => {

  //  ----------[ Select Button Object ]----------

  return (
    <FormControl variant="outlined" fullWidth size="small" style={{ width: widthPx }}>
      <InputLabel id={`label${title}`}>
        {title}
      </InputLabel>
      <Select id={`combo${title}`} labelId={`label${title}`}
        input={<OutlinedInput label={title} />}
        value={selected}
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => callback(event.target.value, userData)}
      >
        {values.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
    