//  #######################
//  ##    CBS Tooltip    ##
//  #######################

import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';

//  ==========[ Constants ]==========

const TOOLTIP_STANDARD_DELAY: number = 500;

//  =================================
//            Object Caller
//  =================================

interface CBSTooltipOldProps {
  element: JSX.Element;
  tip: string;
  disabled: boolean;
};
  
export const CBSTooltipOld: FC<CBSTooltipOldProps> = ({ element, tip, disabled }) => {
  return (
    disabled ?
      element
    :
      <Tooltip title={tip} arrow enterDelay={TOOLTIP_STANDARD_DELAY}>
        {element}
      </Tooltip>
  );
};

interface CBSTooltipProps {
  children: React.ReactNode;
  tip: string;
  disabled: boolean;
};
  
export const CBSTooltip = ({ children, tip, disabled }: CBSTooltipProps) => {
  return (
    disabled ?
      <>{children}</>
    :
      <Tooltip title={tip} arrow enterDelay={TOOLTIP_STANDARD_DELAY}>
        <>{children}</>
      </Tooltip>
  );
};
