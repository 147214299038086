import React from 'react';
import { Content, ItemCardGrid, Page, Header, LinkButton } from '@backstage/core-components';
import { ProfileInfo } from '@backstage/core-plugin-api';
import { Card, CardActions, CardContent, CardMedia } from '@material-ui/core';

import { CBSHeaderAvatar, CBSItemCardHeader, useChimeraEnvironment, CONST, TOOLKIT } from '@internal/plugin-cbs-core';

//  ==========[ Home Page Object ]==========

  interface HomePageProps {
    profile: ProfileInfo | undefined;
  };
  
  export const HomePage = ({ profile }: HomePageProps) => {
  
  //  ----------[ User Profile Object ]----------

  const UserProfile = () => {
    if (profile === undefined) {
      return <CBSHeaderAvatar message="Loading ..." />
    }
    if (profile.displayName === undefined) {
      return <CBSHeaderAvatar message="Unkmown user" />
    }
    return <CBSHeaderAvatar name={profile.displayName} image={profile.picture} />
  }

  //  ----------[ Crew Explorer Object ]----------

  const CrewExplorer = () => {
    const environment = useChimeraEnvironment();

    if (profile?.displayName === undefined || environment === CONST.TEST_ENVIRONMENT) {
      return (<LinkButton to="/cbs-crew" color="primary" disabled>Unavailable</LinkButton>);
    }

    return (<LinkButton to="/cbs-crew" color="primary">EXPLORE</LinkButton>);
  }

  const DamsExplorer = () => {
    if (profile?.displayName === undefined) {
      return (<LinkButton to="/cbs-dams" color="primary" disabled>Unavailable</LinkButton>);
    }

    return (<LinkButton to="/cbs-dams" color="primary">EXPLORE</LinkButton>);
  }

  //  ----------[ Home Page provider ]----------

  return (
    <Page themeId="app">
      <Header title="IoTPBS Products" subtitle="IoT Platform Base Services">
        <UserProfile />
      </Header>
      <Content>
        <ItemCardGrid>
          <Card>
            <CardMedia>
              <CBSItemCardHeader title="DAMS" subtitle="Digital Appliance Management Service" style='apis' />
            </CardMedia>
            <CardContent>
              A service to manage digital appliances
            </CardContent>
            <CardActions>
              <DamsExplorer />
            </CardActions>
          </Card>
          <Card>
            <CardMedia>
              <CBSItemCardHeader title="CrEW" subtitle="Cross Environment Wakeup" style='service' />
            </CardMedia>
            <CardContent>
              A tool to move connectivity modules between PROD, UAT and TEST environments
            </CardContent>
            <CardActions>
              <CrewExplorer />
            </CardActions>
          </Card>
          {TOOLKIT.useLocalDevelopment() &&
            <Card>
              <CardMedia>
                <CBSItemCardHeader title="IoT-CF Tool" subtitle="IoT - Configuration Files Tool" style='documentation' />
              </CardMedia>
              <CardContent>
                A tool to manage configuration files
              </CardContent>
              <CardActions>
                <LinkButton to="/cbs-iotcftool" color="primary">EXPLORE</LinkButton>
              </CardActions>
            </Card>
          }
        </ItemCardGrid>
      </Content>
    </Page>
  );
};

