//  #########################
//  ##    CBS Logo Full    ##
//  #########################

import React from 'react';
import { makeStyles } from '@material-ui/core';
import ChimeraLogoFull from '../../images/Chimera.png';
import { SIDEBAR_LOGO_HEIGHT } from './CBSCustom';

//  ==========[ Styles ]==========

const useStyles = makeStyles({
  logo: {
    maxHeight: SIDEBAR_LOGO_HEIGHT,
  },
});

//  =================================
//            Object Caller
//  =================================

const CBSLogoFull = () => {
  const classes = useStyles();
  return <img src={ChimeraLogoFull} className={classes.logo} alt="Logo" />;
};

export default CBSLogoFull;
