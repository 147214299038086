//  ###########################
//  ##    CBS Plugin Menu    ##
//  ###########################

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, ListItemIcon, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
// import { ApiRef, BackstageIdentityApi, errorApiRef, OAuthApi, OpenIdConnectApi, ProfileInfoApi, SessionApi, useApi } from '@backstage/core-plugin-api';
import SignOutIcon from '@material-ui/icons/Block';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MicrosoftAuthApiRefType } from '@internal/plugin-cbs-auth';

//  ==========[ Styles ]==========

const useStyles = makeStyles<Theme>(
  theme => ({
    button: {
      color: theme.palette.common.white,
    },
  }),
  { name: 'ChimeraPluginMenu' },
);

//  =================================
//            Object Caller
//  =================================

interface CBSPluginMenuProps {
  authApiRef: MicrosoftAuthApiRefType;
  // authApiRef: ApiRef<OAuthApi & OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi>;
};

export const CBSPluginMenu = ({ authApiRef }: CBSPluginMenuProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  //  ----------[ Hooks ]----------

  const authApi = useApi(authApiRef);     
  const errorApi = useApi(errorApiRef);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>(undefined);

  //  ----------[ Handlers ]----------

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setOpen(false);
  };
  
  //  ----------[ User Menu Object ]----------

  return (
    <>
      <IconButton data-testid="user-menu" aria-label="more" className={classes.button}
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} 
        onClose={handleClose}
      >
        <MenuItem data-testid="sign-out"
          onClick={() => 
            authApi
              .signOut()
              .then(() => navigate(-1))
              .catch(error => errorApi.post(error))
          }
        >
          <ListItemIcon>
            <SignOutIcon />
          </ListItemIcon>
          Revoke Consent
        </MenuItem>
      </Menu>
    </>
  );
};
