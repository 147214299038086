//  ###############################
//  ##    CBS Page Components    ##
//  ###############################

import React from 'react';
import { Content, Header, HeaderLabel } from '@backstage/core-components';
import { Box, CircularProgress, Grid, IconButton, makeStyles, Typography, Theme } from '@material-ui/core';
import UnauthorizedDark from '../images/UnauthorizedDark.png';
import UnauthorizedLight from '../images/UnauthorizedLight.png';
import { Alert } from '@material-ui/lab';
import GeneralIcon from '@material-ui/icons/MoreVert';
import * as TOOLKIT from '../utils/CBSToolkit';

//  ==========[ Styles ]==========

const useStyles = makeStyles<Theme>(
  theme => ({
    icon: {
      maxHeight: 100,
      maxWidth: 100,
    },  
    whiteButton: {
      color: theme.palette.common.white,
    },
  }),
  { name: 'BackstagePageComponents' },
);

// ---------------------------------------------------------------------------------------------------
//    Main Page Component
// ---------------------------------------------------------------------------------------------------
export const CBSPageMain = ({ 
  title, 
  subtitle,
  environment,
  version,
  openToolsCallback,
  children
}:{
  title: string,
  subtitle: string,
  environment?: string | undefined,
  version?: string | undefined,
  openToolsCallback?: (isOpen: boolean) => void;
  children: React.ReactNode
}) => {
  const classes = useStyles();

  return (
    <>
      <Header title={title} subtitle={subtitle} >     
        <HeaderLabel label="Environment" value={environment || 'Unknown'} />
        <HeaderLabel label="Owner" value="CBS" />
        <HeaderLabel label="Version" value={version || 'Unknown'} />
        {openToolsCallback &&
          <IconButton data-testid="user-menu" aria-label="more" className={classes.whiteButton} onClick={() => openToolsCallback(true)} >
            <GeneralIcon />
          </IconButton>
        }                          
      </Header>
      {children}
    </>
  );
}

// ---------------------------------------------------------------------------------------------------
//    Loading Page Component
// ---------------------------------------------------------------------------------------------------
export const CBSPageLoading = () => {
  return (
    <Content>
      <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}
        style={{ position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    </Content>
  );
}

// ---------------------------------------------------------------------------------------------------
//    Error Page Component
// ---------------------------------------------------------------------------------------------------
export const CBSPageError = ({ 
  message
}:{ 
  message: string 
}) => {
  return (
    <Content>
      <Alert severity="error">
        {message}
      </Alert>    
    </Content>   
  );
}

// ---------------------------------------------------------------------------------------------------
//    Unauthorized Page Component
// ---------------------------------------------------------------------------------------------------
export const CBSPageUnauthorized = () => {
  const darkTheme = TOOLKIT.useDarkThemePalette();
  const classes = useStyles();
  
  return (
    <Content>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Box sx={{minHeight: (window.innerHeight - 100) / 4}}/>
            <img className={classes.icon}
              src={darkTheme ? UnauthorizedDark : UnauthorizedLight} 
              alt = 'Unauthorized'
            />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography variant="h6">Unauthorized</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={0}>
        <Grid item>
          <Typography variant="body1">You do not have the permissions to access this page</Typography>
        </Grid>
      </Grid>
    </Content>
  );
}

// ---------------------------------------------------------------------------------------------------
//    Impersonating Page Component
// ---------------------------------------------------------------------------------------------------
export const CBSPageImpersonating = () => {
  return (
    <Box sx={{position: 'fixed', top: '0', left: '0', zIndex: '5000', width: '100%', color:'white', bgcolor: '#ff0000bb', textAlign: 'center', letterSpacing: 10}}>
      <Typography variant="caption">IMPERSONATING</Typography>
    </Box>    
  );
}



