//  ######################
//  ##    CBS Custom    ##
//  ######################
 
import { themes } from '@backstage/theme';
import { sidebarConfig } from '@backstage/core-components';

export const SIDEBAR_LOGO_HEIGHT = 160;
export const SIDEBAR_LOGO_SPACE = 24;
export const SIDEBAR_MARGIN_LEFT = 40;
export const SIDEBAR_MARGIN_LEFT_CLOSED = 8;

const SIDEBAR_LIGHT_BACKCOLOR = '#2E2E2E';
const SIDEBAR_LIGHT_INDICATOR = '#EE5B0F';
const SIDEBAR_DARK_INDICATOR = '#EE490A';

const SIDEBAR_INDICATOR_WIDTH = 4;

export const rootCustomizations = () => {
  const lightTheme = themes.light.getTheme("v4");
  if (lightTheme !== undefined) {
    lightTheme.palette.navigation.background = SIDEBAR_LIGHT_BACKCOLOR;
    lightTheme.palette.navigation.indicator = SIDEBAR_LIGHT_INDICATOR;
  }
  const darkTheme = themes.dark.getTheme("v4");
  if (darkTheme !== undefined) {
    darkTheme.palette.navigation.indicator = SIDEBAR_DARK_INDICATOR;
  }
  sidebarConfig.selectedIndicatorWidth = SIDEBAR_INDICATOR_WIDTH;
}
