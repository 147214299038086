//  #######################
//  ##    CBS Toolkit    ##
//  #######################

import { useApi, configApiRef, appThemeApiRef } from '@backstage/core-plugin-api';
// import { Config } from '@backstage/config';
import { makeStyles, Theme } from '@material-ui/core';
import { isLocalDevelopment } from 'backstage-plugin-cbs-common';

import * as CONST from "../statics/CBSConstants";
import * as STRUCT from "../statics/CBSStructures";
import React from 'react';

//  ----------[ Constants ]----------

const BORDER_BLUE_LIGHT: string = "#93B8E4";
const BORDER_BLUE_DARK: string = "#677E99";

const TEXT_MORE_SUBTLE_LIGHT: string = "#A5A5A5";
const TEXT_MORE_SUBTLE_DARK: string = "#9F9F9F";

//  ----------[ Configuration ]----------

export const useBackendServiceUrl = (service: string) => {
  const config = useApi(configApiRef);   
  const baseUrl = config.getString('backend.baseUrl');  

  return `${baseUrl}/api/proxy/${service}`;
}

//  ----------[ Theme Palette ]----------

export const useDarkThemePalette = () => {
  const activeTheme = useApi(appThemeApiRef).getActiveThemeId();
  return activeTheme === 'dark' || (activeTheme === undefined && window.matchMedia('(prefers-color-scheme: dark)').matches);
}

//  ----------[ Color Styles ]----------

export const useColorStyles = makeStyles<Theme>(
  theme => ({
    ok: {
      color: theme.palette.status.ok,
    },
    warning: {
      color: theme.palette.status.warning,
    },
    error: {
      color: theme.palette.status.error,
    },
    textSubtle: {
      color: theme.palette.textSubtle,
    },
    textMoreSubtle: {
      color: theme.palette.type === 'light' ? TEXT_MORE_SUBTLE_LIGHT : TEXT_MORE_SUBTLE_DARK,
    },
    textVerySubtle: {
      color: theme.palette.textVerySubtle,
    },
    errorButton: {
      color: theme.palette.status.error,
      borderColor: theme.palette.status.error,
    },
    iconButton: {
      width: 30, 
      height: 30, 
      border: "1px solid", 
      borderRadius: 4, 
      borderColor: theme.palette.type === 'light' ? BORDER_BLUE_LIGHT : BORDER_BLUE_DARK
    },
    textArea: {
      color: theme.palette.textContrast,
      // fontWeight: "bold", 
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      // WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical"
    },
    divider: {
      minWidth: 1, 
      minHeight: 1, 
      backgroundColor: theme.palette.textVerySubtle,
    },
  })
);

//  ----------[ Environment ]----------

export const environmentNameFromId = (value: number) => {
  return CONST.ENVIRONMENT_LIST.find((obj) => {
    return obj.value === value.toString();
  })?.label || "";
}

export const environmentIdFromName = (value: string) => {
  return CONST.ENVIRONMENT_LIST.find((obj) => {
    return obj.label === value;
  })?.value || "0";
}

//  ----------[ Date utilities ]----------

export function parseYYYYMMDD(date: string) : Date | undefined { 
  const matchResults = date.match(/\d\d/g); 
  if (matchResults) {
    const [C,Y,M,D] = matchResults;  
    const yy = parseInt(C + Y, 10);
    const mm = parseInt(M, 10) - 1;
    const dd = parseInt(D, 10);

    return new Date(yy, mm, dd);     
  }
 
  return undefined
}

//  ----------[ String utilities ]----------

export const splitTextLines = (text: string) => {
  const cleaned = text.replace(/^\n+|\n+$/g, '');
  const lines = cleaned.split('\n');

  if (lines.length === 1) {
    return cleaned;
  }

  const elements: JSX.Element[] = [<span key={0}>{lines[0]}</span>];
  for (let index = 1; index < lines.length; index++) {
    elements.push(<span key={index}><br/>{lines[index]}</span>);
  }

  return elements;
}

//  ----------[ OData Filter Building ]----------

// TO REMOVE - Can be removed if old CrEW controllers are removed
const prepareMacAddress = (macAddress: string) => {
  const trimmed = macAddress.trim().split(/[\s,;]/);
  let odata: string = "";
  trimmed.forEach((mac) => {
    if (mac !== '') {   // Only valid MAC Addresses (avoid problems with multiple consecutive separators)
      odata += `${odata ===  "" ? "" : " or "}startswith(macAddress, '${mac.replace(/:/g, '')}') eq true`;
    }
  });
  return trimmed.length === 1 ? odata : `(${odata})`;
}

// TO REMOVE - Can be removed if old CrEW controllers are removed
export const odataFromSearchParams = (params: STRUCT.SearchParams) => {
  let odata: string = "";

  if (params.movable !== undefined && params.movable !== CONST.ALL_MODULES) {
    odata += `${odata ===  "" ? "?$filter=" : "+and+"}isWhitelisted+eq+${params.movable}`;
  }
  if (params.source !== undefined && params.source !== CONST.SOURCE_ALL) {
    odata += `${odata ===  "" ? "?$filter=" : "+and+"}serializationSourceID+eq+${params.source}`;
  }
  
  if (params.macAddress !== undefined && params.macAddress.trim() !== "") {
    odata += `${odata ===  "" ? "?$filter=" : "+and+"}${prepareMacAddress(params.macAddress)}`;
  }
  if (params.unitType !== undefined && params.unitType.trim() !== "") {
    odata += `${odata ===  "" ? "?$filter=" : "+and+"}startswith(unitType,'${params.unitType}')+eq+true`;
  }
  if (params.configuration !== undefined && params.configuration.trim() !== "") {
    odata += `${odata ===  "" ? "?$filter=" : "+and+"}startswith(configurationDescription,'${params.unitType}')+eq+true`;
  }

  if (params.serialization !== undefined && params.serialization !== "") {
    const end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    end.setSeconds(59);
    end.setMilliseconds(999);

    const start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    start.setSeconds(0);
    start.setMilliseconds(0);

    if (params.serialization !== CONST.SERIALIZATION_1_DAY.toString()) {
      let days: number = 29;  // _SERIALIZATION_30_DAY
      if (params.serialization === CONST.SERIALIZATION_2_DAY.toString()) {
        days = 1;
      } 
      else if (params.serialization === CONST.SERIALIZATION_7_DAY.toString()) {
        days = 6;
      }
      start.setDate(start.getDate() - days);
    }

    odata += `${odata ===  "" ? "?$filter=" : " and "}serializationTms ge ${start.toISOString().substring(0, 19)} and serializationTms le ${end.toISOString().substring(0, 19)}`;
  }
  
  return odata;
}

//  ----------[ Generic Operation Result ]----------

// TO REMOVE - Can be removed if old CrEW controllers are removed
export const operationResultFromResults = (macAddress: string, results: any[]): STRUCT.OperationResult | undefined => {
  const result = results.find(r => r.macAddress === macAddress);
  return result !== undefined ? {macAddress: result.macAddress, success: result.success, issues: result.issues} : undefined;
}

//  ----------[ Generic Wait Function ]----------

export const genericWait = async (millis: number) => {
  await new Promise<void>((resolve, _) => setTimeout(() => resolve(), millis));
}

//  ----------[ Local Development Detection Function ]----------

export const useLocalDevelopment = (): boolean => {
  const config = useApi(configApiRef);
  return isLocalDevelopment(config.getString('app.baseUrl'));
}
