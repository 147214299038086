import { OAuth2, OAuthApiCreateOptions } from '@backstage/core-app-api';
import { microsoftPublicInfo } from 'backstage-plugin-cbs-common';
import { microsoftPublicAuthApiRef } from '../apis/MicrosoftTokenAuthApis';

import { 
  microsoftCrewInfo, microsoftPublicCrewInfo, 
  microsoftDamsInfo, microsoftPublicDamsInfo, 
  microsoftIotcftoolInfo,
} from 'backstage-plugin-cbs-common';
import { 
  microsoftCrewAuthApiRef, microsoftPublicCrewAuthApiRef,
  microsoftDamsAuthApiRef, microsoftPublicDamsAuthApiRef,
  microsoftIotcftoolAuthApiRef,
} from '../apis/MicrosoftTokenAuthApis';

const DEFAULT_ENV = 'development';

const AZURE_SCOPES = [
  'openid',
  'offline_access',
  'profile',
  'email',
  'User.Read',
];

/**
 * @public
 */
export class MicrosoftPublicAuth {
  static create(options: OAuthApiCreateOptions): typeof microsoftPublicAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftPublicInfo.providerId,
        title: microsoftPublicInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}

/*
 * Add here the new API implememtations - Search tag: [NEW_TOKEN_PROVIDER] - #3
 */
/**
 * @public
 */
export class MicrosoftCrewAuth {
  static create(scope: string | undefined, options: OAuthApiCreateOptions): typeof microsoftCrewAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftCrewInfo.providerId,
        title: microsoftCrewInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = scope ? [`${scope}/crew`, ...AZURE_SCOPES] : AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}
/**
 * @public
 */
export class MicrosoftPublicCrewAuth {
  static create(scope: string | undefined, options: OAuthApiCreateOptions): typeof microsoftPublicCrewAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftPublicCrewInfo.providerId,
        title: microsoftPublicCrewInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = scope ? [`${scope}/crew`, ...AZURE_SCOPES] : AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}

/**
 * @public
 */
export class MicrosoftDamsAuth {
  static create(scope: string | undefined, options: OAuthApiCreateOptions): typeof microsoftDamsAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftDamsInfo.providerId,
        title: microsoftDamsInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = scope ? [`${scope}/dams`, ...AZURE_SCOPES] : AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}
/**
 * @public
 */
export class MicrosoftPublicDamsAuth {
  static create(scope: string | undefined, options: OAuthApiCreateOptions): typeof microsoftPublicDamsAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftPublicDamsInfo.providerId,
        title: microsoftPublicDamsInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = scope ? [`${scope}/dams`, ...AZURE_SCOPES] : AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}

/**
 * @public
 */
export class MicrosoftIotcftoolAuth {
  static create(scope: string | undefined, options: OAuthApiCreateOptions): typeof microsoftIotcftoolAuthApiRef.T {
    const {
      environment = DEFAULT_ENV,
      provider = {
        id: microsoftIotcftoolInfo.providerId,
        title: microsoftIotcftoolInfo.title,
        icon: () => null,
      },
      oauthRequestApi,
      discoveryApi,
      defaultScopes = scope ? [`${scope}/iotcftool`, ...AZURE_SCOPES] : AZURE_SCOPES,
      configApi,
    } = options;

    return OAuth2.create({ discoveryApi, oauthRequestApi, provider, environment, defaultScopes, configApi });
  }
}
/*
 * ----------
 */
