//  #############################
//  ##    CBS RequestDialog    ##
//  #############################

import  React, { useState } from "react"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CBSThemeHeader } from "../elements/CBSThemeHeader";
import { composeErrorMessage } from './common';
import { titleWithDictionary } from './common';

//  ==========[ Types ]==========

type DialogProps = {
  title: string;
  themeId?: string;
  message: string;
  operation: string;
  operating: string;
  operationFn: Function;
  keyFields?: string[];
};

export type DialogHooks = {
  open: boolean;
  value: any;
  reload: number;
};

export type DialogOperationResult = {
  isSuccess: boolean;
  detectedProblems?: any;
};

//  =================================
//            Object caller
//  =================================

export const CBSRequestDialog = (props: DialogProps, hooks: DialogHooks, setHooks: Function) => {

  //  ----------[ Constants ]---------

  const SIDE_TITLE_MARGIN: number = 16;

  const BUTTON_MIN_WIDTH: number = 120;
  const BUTTON_MARGIN_RIGHT: number = 18;
  const BUTTON_VERTICAL_MARGIN: number = 12;

  //  ----------[ Hooks ]---------

  const [operating, setOperating] = useState(false);
  const [operationError, setOperationError] = useState<string>();
  
  //  ----------[ Handlers ]----------

  const handleButtonClick = () => {
    void (async () => {
      setOperating(true);
      try {
        const result = await props.operationFn!(hooks.value);

        if (result.isSuccess) {
          setOperating(false);
          setHooks({ ...hooks, open: false, reload: hooks.reload + 1 });
        }
        else {
          setOperating(false);
          setOperationError(composeErrorMessage(result.detectedProblems));
        }
      }
      catch (error) {
        setOperating(false);
        setOperationError(error instanceof Error ? error.message : "Unknown error");
      }
    })();
  };

  //  ----------[ Title component ]----------

  const requestDialogTitle = () => {
    return (
      <Grid container spacing={0} direction="row" alignItems="baseline">
        <Grid item>
          <Typography variant="h6" style={{ color: 'white' }}>
            {props.title}
          </Typography>
        </Grid>
        {props.keyFields && 
          <>
            <Grid item>
              <Typography variant="body1" style={{ fontWeight: 'bold', color: "whitesmoke", marginLeft: 24, marginRight: 12 }}>
                Id :
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ color: 'white' }}>
                {titleWithDictionary(hooks.value, props.keyFields)}
              </Typography>
            </Grid>
          </>
        }
      </Grid>
    );
  };

  //  ----------[ Button components ]----------

  const cancelButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        <Button variant="contained" color="primary" fullWidth
          onClick={() => setHooks({ ...hooks, open: false })} 
        >
          CANCEL
        </Button>
      </Box>
    );
  };

  //  ----------

  const operationButton = () => {
    return (
      <Box sx={{ minWidth: BUTTON_MIN_WIDTH, marginRight: BUTTON_MARGIN_RIGHT, marginTop: BUTTON_VERTICAL_MARGIN, marginBottom: BUTTON_VERTICAL_MARGIN }}>
        {operating ?
          <Button variant="contained" color="secondary" fullWidth disabled >
            <CircularProgress size='1rem' variant='indeterminate' color='inherit' style={{ marginRight: 12 }} />
            {props.operating} 
          </Button>
        :
          <Button variant="contained" color="secondary" fullWidth
            onClick={() => handleButtonClick()} 
          >
            {props.operation} 
          </Button>
        }
      </Box>
    );
  };
  
  //  ----------[ Table dialog object ]----------

  return (
    <Dialog maxWidth="xl"
      open={hooks.open} 
      onClose={() => setHooks({ ...hooks, open: false })} 
      aria-labelledby="dialog-title"
      aria-describedby="dialog-message"
    >
      {props.themeId ?
        <DialogTitle id="dialog-title" style={{ padding: 0, margin: 0 }}>
          <CBSThemeHeader 
            title={requestDialogTitle()}
            themeId={props.themeId} 
            size="small"
          />
        </DialogTitle>
      :
        <DialogTitle id="dialog-title">
          {props.title}
        </DialogTitle>
      }
      <DialogContent dividers={!props.themeId}>
        {operationError === undefined ?
          <Typography variant="body1" style={{ marginLeft: SIDE_TITLE_MARGIN, marginRight: SIDE_TITLE_MARGIN, marginTop: 30, marginBottom: 16 }}>
            {props.message}
          </Typography>
        :
          <Alert severity="error" style={{ marginLeft: SIDE_TITLE_MARGIN, marginRight: SIDE_TITLE_MARGIN, marginTop: 30, marginBottom: 16 }}>
            {operationError}
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        {operationError === undefined && operationButton()}
        {cancelButton()}
      </DialogActions>
    </Dialog>
  );  
}