import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

// [CBS]  Only one identity provider. We don't need an array

export const chimeraProvider = {
  id: 'microsoft-auth-provider',
  title: 'Microsoft',
  message: 'Sign In using Microsoft Azure AD',
  apiRef: microsoftAuthApiRef,
};
