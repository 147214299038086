//  #############################
//  ##    CBS Header Avatar    ##
//  #############################

import React, { FC } from 'react';
import { themes } from '@backstage/theme';
import { Typography, Avatar, makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { indigo } from '@material-ui/core/colors';
import { CBSUserMenu } from './CBSUserMenu';

//  ==========[ Styles ]==========

const useStyles = makeStyles<Theme>(
  theme => ({
    label: {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize,
    },
    avatar: {
      color: theme.palette.getContrastText(indigo[500]),
      backgroundColor: indigo[500],
      fontSize: 20,
      height: 48,
      width: 48,
      border: `1px solid ${themes.dark.getTheme("v4")?.palette.textSubtle || "#CCCCCC"}`
    },
  }),
  { name: 'ChimeraHeaderAvatar' },
);

//  =================================
//            Object Caller
//  =================================

interface CBSHeaderAvatarArgs {
  name?: string;
  image?: string;
  message?: string;
}

export const CBSHeaderAvatar: FC<CBSHeaderAvatarArgs> = ({ name, image, message }) => {
  const classes = useStyles();

  //  ----------[ Utility Methods ]----------

  const getInitials = (text: string) => {
    const words = text.split(" ");
    if (words.length === 2)
      return words[0].substring(0, 1) + words[1].substring(0, 1);
    return text.substring(0, 1);
  }

  //  ----------[ Avatar Component ]----------

  const prepareAvatar = () => {
    if (message !== undefined) {
      return (
        <Avatar className={classes.avatar} />
      );
    } 
    else if (name !== undefined) {
      return (
        <Avatar className={classes.avatar} src={image}>{getInitials(name)}</Avatar>
      );
    }

    return (
      <Avatar className={classes.avatar} src={image} />
    );
}

  //  ----------[ Header Avatar Object ]----------

  return (
    <Grid item>
      <Grid container spacing={0} direction="column" alignItems="flex-end">
        <Grid item>
          <Grid container spacing={0} direction="row" alignItems="center">
            <Grid item style={{ marginRight: 6 }}>
              <CBSUserMenu whiteMenu />
            </Grid>
            <Grid item> 
              {prepareAvatar()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.label}>{message ?? name ?? ""}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}