//  ################################
//  ##    CBS Item Card Header    ##
//  ################################

import React from 'react';
import { createStyles, makeStyles, Typography, WithStyles, Theme } from '@material-ui/core';

//  ==========[ Styles ]==========

const createStyle = (theme: Theme, themeId: string) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(2, 2, 3),
  backgroundImage: theme.getPageTheme({ themeId }).backgroundImage,
  backgroundPosition: 0,
  backgroundSize: 'inherit',
})

const styles = (theme: Theme) => createStyles({
  root: createStyle(theme, "app"),
  service: createStyle(theme, "service"),
  apis: createStyle(theme, "apis"),
  documentation: createStyle(theme, "documentation"),
})

const useStyles = makeStyles(styles, { name: 'ChimeraItemCardHeader' });

const useThemeStyle = (style: string | undefined) => {
  const classes = useStyles();

  switch (style) {
    case "service":
    case "apis":
    case "documentation":
      return classes[style];
    default:
      return classes.root
  }
}

//  =================================
//            Object Caller
//  =================================

export type CBSItemCardHeaderProps = Partial<WithStyles<typeof styles>> & {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  style?: string;
};

export function CBSItemCardHeader(props: CBSItemCardHeaderProps) {
  const { title, subtitle, style } = props;
  const selectedStyle = useThemeStyle(style);

  return (
    <div className={selectedStyle}>
      {subtitle && (
        <Typography variant="subtitle2" component="h3">
          {subtitle}
        </Typography>
      )}
      {title && (
        <Typography variant="h6" component="h4">
          {title}
        </Typography>
      )}
    </div>
  );
} 
