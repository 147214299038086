import { /* ApiRef, */ createApiRef } from '@backstage/core-plugin-api';
// import { OAuthApi, OpenIdConnectApi, ProfileInfoApi, BackstageIdentityApi, SessionApi } from '@backstage/core-plugin-api';
import { microsoftPublicInfo } from 'backstage-plugin-cbs-common'
import { 
  microsoftCrewInfo, microsoftPublicCrewInfo,
  microsoftDamsInfo, microsoftPublicDamsInfo,
  microsoftIotcftoolInfo,
} from 'backstage-plugin-cbs-common'
import { MicrosoftAuthApiRefType } from '../../types';

/**
 * @public
 */
export const microsoftPublicAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
  id: microsoftPublicInfo.apiRefId,
});

/*
 * Add here the new API ids - Search tag: [NEW_TOKEN_PROVIDER] - #2
 */
/**
 * @public
 */
export const microsoftCrewAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
  id: microsoftCrewInfo.apiRefId,
});
/**
 * @public
 */
export const microsoftPublicCrewAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
  id: microsoftPublicCrewInfo.apiRefId,
});

/**
 * @public
 */
export const microsoftDamsAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
  id: microsoftDamsInfo.apiRefId,
});
/**
 * @public
 */
export const microsoftPublicDamsAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
    id: microsoftPublicDamsInfo.apiRefId,
});

/**
 * @public
 */
export const microsoftIotcftoolAuthApiRef: MicrosoftAuthApiRefType = createApiRef({
    id: microsoftIotcftoolInfo.apiRefId,
});
/*
 * ----------
 */
