//  ##########################
//  ##    CBS BasicTable    ##
//  ##########################

import React from "react";
import { Table, TableColumn } from '@backstage/core-components';
import { Typography } from "@material-ui/core";

//  ==========[ Constants ]==========

const STD_MAX_ROWS: number = 20;
const STD_ROWS_SIZES: number[] = [4, 6, 8, 10, STD_MAX_ROWS];   // Given the alternate coloring of the lines and that of the pagination footer, even numbers have a better effect

//  =================================
//            Object caller
//  =================================

interface CBSBasicTableProps {
  columns: TableColumn[];
  rows: any[];
  tag: string;
  minWidth?: number;
  maxRows?: number;
  message?: string;
};

export const CBSBasicTable = ({ columns, rows, tag, minWidth, maxRows, message }: CBSBasicTableProps) => {

  //  ----------[ Normalizations ]----------

  let width = 0;
  if (minWidth) {
    width = minWidth;
  }
  else {
    columns.forEach((column: TableColumn) => {
      width += Number(column.width);
    })
  }

  //  ----------

  let numRows = STD_MAX_ROWS;   // Protected, so only the expected values are used
  if (maxRows) {
    for (const size of STD_ROWS_SIZES) {
      if (maxRows <= size) {
        numRows = size;
        break;
      }
    }  
  }

  //  ----------[ Identification column addition ]----------

  if (rows.length !== 0 && rows[0].id === undefined) {
    let counter = 0;
    for (const row of rows) {
      row.id = `${tag}-${++counter}`;
    }  
  }
  
  //  ----------[ Components ]---------

  const tableHeader = () => {
    return (
      <></>
    );
  }

  //  ----------

  const emptyTable = () => {
    return (
      <Typography variant="body2" color="secondary" align='center' style={{ paddingTop: "4px", paddingBottom: "4px" }}>
        {message}
      </Typography>
    );
  }

  //  ----------[ Main object ]---------

  return (
    <Table
      components={{
        Toolbar: (_) => tableHeader()
      }}
      options={{ 
        search: false, 
        paging: rows.length > numRows,
        pageSize: numRows,
        pageSizeOptions: STD_ROWS_SIZES,
        padding: 'dense',
      }}
      columns={columns}
      data={rows}
      emptyContent={emptyTable()}
      style={{ width: width }}
    />
  );
}