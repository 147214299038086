//  ################################
//  ##    CBS Chimera Settings    ##
//  ################################

import { useApi, configApiRef } from '@backstage/core-plugin-api';
import * as TOLKIT from './CBSToolkit';

//  ----------[ Getters ]----------

export const useChimeraEnvironment = () => {
    const config = useApi(configApiRef);   
    const environment = config.getString('cbsChimeraSettings.environment');  

    return parseInt(TOLKIT.environmentIdFromName(environment), 10);
}
