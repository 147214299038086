import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const cbsDamsPlugin = createPlugin({
  id: 'cbs-dams',
  routes: {
    root: rootRouteRef,
  },
});

export const CbsDamsPage = cbsDamsPlugin.provide(
  createRoutableExtension({
    name: 'CbsDamsPage',
    component: () =>
      import('./components/DamsComponent').then(m => m.DamsComponent),
    mountPoint: rootRouteRef,
  }),
);
