//  ##########################
//  ##    CBS PagedTable    ##
//  ##########################

import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { NavLink, useNavigate } from 'react-router-dom';
import { useApi } from '@backstage/core-plugin-api';
import { Table } from '@backstage/core-components';
import { Backdrop, Box, CircularProgress, Grid, IconButton, Link, makeStyles, Tooltip, Typography, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import Exporticon from '@material-ui/icons/OpenInNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as XLSX from 'xlsx';
import { getCspNonce } from 'backstage-plugin-cbs-common';
import { CBSPagingCard, PagingCardInfo } from './CBSPagingCard';
import { CBSFilterCard, FilterCardInfo, FilterElement } from './CBSFilterCard';
import { CBSDetailPopup, DetailHooks, DETAIL_MODE_SHOW, DETAIL_MODE_EDIT, DETAIL_MODE_CREATE } from './CBSDetailPopup';
import { CBSDetailProps, CBSComboFilterInfo, CBSFetchParams, CBSDetailAuths, CBSTableColumn, CBSTableSettings, CBSMultiValueFilterInfo, CBSTableDelegate, CBSTablePresets, CBSLinkArguments, CBSBasicFilterInfo, CBSQueryInfo, CBSQueryInfoFilter } from './types';
import { CBSRequestDialog, DialogHooks, DialogOperationResult } from './CBSRequestDialog';
import { createDictionary, findWithDictionary, idWithDictionary, KEY_FIELD_ID, linkArgumentsCompose } from './common';
import FilterLightIcon from '../images/FilterLight.png';
import FilterLightIcon1 from '../images/FilterLight1.png';
import FilterLightIcon2 from '../images/FilterLight2.png';
import FilterLightIcon3 from '../images/FilterLight3.png';
import FilterLightIcon4 from '../images/FilterLight4.png';
import FilterLightIcon5 from '../images/FilterLight5.png';
import FilterLightIcon6 from '../images/FilterLight6.png';
import FilterLightIcon7 from '../images/FilterLight7.png';
import FilterLightIcon8 from '../images/FilterLight8.png';
import FilterLightIcon9 from '../images/FilterLight9.png';
import FilterDarkIcon from '../images/FilterDark.png';
import FilterDarkIcon1 from '../images/FilterDark1.png';
import FilterDarkIcon2 from '../images/FilterDark2.png';
import FilterDarkIcon3 from '../images/FilterDark3.png';
import FilterDarkIcon4 from '../images/FilterDark4.png';
import FilterDarkIcon5 from '../images/FilterDark5.png';
import FilterDarkIcon6 from '../images/FilterDark6.png';
import FilterDarkIcon7 from '../images/FilterDark7.png';
import FilterDarkIcon8 from '../images/FilterDark8.png';
import FilterDarkIcon9 from '../images/FilterDark9.png';
import * as TOOLKIT from '../utils/CBSToolkit';
import { MicrosoftAuthApiRefType } from '@internal/plugin-cbs-auth';

//  ==========[ Types ]==========

type OrderInfo = {
  field: string;
  direction: string;
};

//  ==========[ Globals ]==========

let pagingInfo: PagingCardInfo = { skip: 0, top: 0 };
let filterInfo: FilterCardInfo = { filters: [] };
const orderInfo: OrderInfo = { field: "", direction: "" };

let tableData: any = undefined;

let tableColumns: CBSTableColumn[] = [];

//  =================================
//            Object caller
//  =================================
  
interface CBSPagedTableProps {
  title: string;
  themeId: string;
  rowsPerPage: number;
  columns: CBSTableColumn[];
  filters?: (string | CBSBasicFilterInfo | CBSComboFilterInfo | CBSMultiValueFilterInfo)[];
  baseUrl: string;
  authApiRef?: MicrosoftAuthApiRefType;
  keyFields: string[];
  readParamsFn: () => CBSFetchParams;
  detailParamsFn?: () => CBSDetailProps;
  deleteParamsFn?: (dictionary: any) => CBSFetchParams;
  inMemory?: boolean;
  settings?: CBSTableSettings;
  presets?: (CBSTablePresets | undefined)[];
  delegate?: CBSTableDelegate;
  authorize?: CBSDetailAuths;
};

export const CBSPagedTable = forwardRef((props: CBSPagedTableProps, ref: ForwardedRef<unknown>) => {
  
  const detailParams = props.detailParamsFn ? props.detailParamsFn() : undefined;
  const createNew: boolean = props.authorize?.detail && detailParams && props.authorize?.create && detailParams.createParamsFn && detailParams.createDetail !== undefined || false;

  const lightFilters = [FilterLightIcon, FilterLightIcon1, FilterLightIcon2, FilterLightIcon3, FilterLightIcon4, FilterLightIcon5, FilterLightIcon6, FilterLightIcon7, FilterLightIcon8, FilterLightIcon9];
  const darkFilters = [FilterDarkIcon, FilterDarkIcon1, FilterDarkIcon2, FilterDarkIcon3, FilterDarkIcon4, FilterDarkIcon5, FilterDarkIcon6, FilterDarkIcon7, FilterDarkIcon8, FilterDarkIcon9];

  //  ----------[ Constants ]---------

  const OPER_FIELD_ID: string = '@oper_id';
  const LINK_FIELD_PFX: string = '@link_';

  const SQL_ASCENT: string = 'asc';
  const SQL_DESCENT: string = 'desc';

  const FORCE_ID_REORDER: number = 1;

  const PAGED_QUERY: number = 0;
  const FULL_QUERY: number = 1;

  const TOOLTIP_DELAY: number = 500;
  const DEFAULT_MAX_SELECTION: number = 5000;

  //  ----------[ Hooks ]---------

  const [filterOpen, setFilterOpen] = useState(props.settings?.openFilters);
  const [counter, setCounter] = useState(-1);
  const [updater, setUpdater] = useState(0);
  const [refresh, setRefresh] = useState(0);

  const [memoryData, setMemoryData] = useState<any[]>([]);
  const [refreshMemory, setRefreshMemory] = useState(0);

  const [detailHooks, setDetailHooks] = useState<DetailHooks>({ open: false, value: undefined, mode: DETAIL_MODE_SHOW, reload: 0 });
  const [deleteHooks, setDeleteHooks] = useState<DialogHooks>({ open: false, value: undefined, reload: 0 });
  const [exportHooks, setExportHooks] = useState<DialogHooks>({ open: false, value: undefined, reload: 0 });

  const navigate = useNavigate();
  const [goTo, setGoTo] = useState('');

  //  ----------[ Styles ]---------

  const classes = TOOLKIT.useColorStyles();
  const darkTheme = TOOLKIT.useDarkThemePalette();

  const backdropClass = makeStyles<Theme>(
    theme => ({
      root: {
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.type === 'light' ? "#ffffff99" : "#00000099",
      }
    })
  )();

  //  ----------[ Initializations ]----------

  if (counter === -1) {
    pagingInfo.skip = 0;
    pagingInfo.top = props.rowsPerPage;

    filterInfo.filters.length = 0;
    if (props.filters !== undefined) {
      let index = 0;
      props.filters.forEach((filter) => {
        if (typeof filter === 'string') {
          const column = props.columns.find(c => c.field === filter);
          if (column !== undefined) {
            if (column.type === 'boolean') {
              filterInfo.filters.push({
                id: index++,
                title: column.title as string,
                name: column.field as string,
                value: "",
                type: column.type as string,
                select: [
                  { value: "true", description: "True" },
                  { value: "false", description: "False" },
                ]
              });
            }
            else {
              filterInfo.filters.push({
                id: index++,
                title: column.title as string,
                name: column.field as string,
                value: "",
                type: column.type as string
              });
            }
          }
        }
        else {
          const column = props.columns.find(c => c.field === filter.field);
          if ('values' in filter) {
            if (column !== undefined) {
              filterInfo.filters.push({
                id: index++,
                title: filter.title ? filter.title : column.title as string,
                name: column.field as string,
                value: "",
                type: column.type as string,
                select: filter.values
              });
            }
          }
          else if ('separators' in filter) {
            if (column !== undefined) {
              filterInfo.filters.push({
                id: index++,
                title: filter.title ? filter.title : column.title as string,
                name: column.field as string,
                value: "",
                type: column.type as string,
                separators: filter.separators,
                hint: filter.hint,
                parseFn: filter.parseFn
              });
            }
          }
          else {
            if (column !== undefined) {
              filterInfo.filters.push({
                id: index++,
                title: filter.title ? filter.title : column.title as string,
                name: column.field as string,
                value: "",
                type: column.type as string,
                parseFn: filter.parseFn
              });
            }
          }
        }
      });
    }

    orderInfo.field = "";
    orderInfo.direction = "";

    props.presets?.forEach((p) => {
      if (p?.key === 'filter') {
        const filter = filterInfo.filters.find((f) => f.name === p.field);
        if (filter !== undefined) {
          filter.value = p.value || '';
        }
      }
      else if (p?.key === 'order' && p?.field !== undefined) {
        orderInfo.field = p.field;
        orderInfo.direction = p.direction !== undefined ? p.direction : 'asc';
      }
    });

    tableColumns = props.columns.map((column) => {
      return { ...column, sorting: true, nextSort: SQL_ASCENT }
    });

    if ((props.authorize?.detail && detailParams) ||
      (props.authorize?.update && detailParams && detailParams.updateParamsFn && detailParams.updateDetail) ||
      (props.authorize?.delete && props.deleteParamsFn)) {
      tableColumns.push({ title: '', field: OPER_FIELD_ID, type: 'string', width: "10px", sorting: false });
    }

    tableColumns.forEach((column) => {
      if (column.link) {
        tableColumns.push({ title: '', field: column.field, type: column.type, width: "10px", hidden: true });
        column.field = `${LINK_FIELD_PFX}${column.field}`;
      }
    });
  }

  //  ----------[ Button components ]----------

  const showRowButtons = (value: any) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {props.authorize?.detail && detailParams &&
          <Tooltip title="View" arrow enterDelay={TOOLTIP_DELAY}>
            <IconButton key={value as string} size='small' aria-label="view" style={{ color: 'gray', marginLeft: '6px' }}
              onClick={(event) => {
                event.stopPropagation();
                setDetailHooks({ ...detailHooks, open: true, value: value, mode: DETAIL_MODE_SHOW });
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        }
        {props.authorize?.update && detailParams && detailParams.updateParamsFn && detailParams.updateDetail &&
          <Tooltip title="Edit" arrow enterDelay={TOOLTIP_DELAY}>
            <IconButton key={value as string} size='small' aria-label="show" style={{ color: 'gray', marginLeft: '6px' }}
              onClick={(event) => {
                event.stopPropagation();
                setDetailHooks({ ...detailHooks, open: true, value: value, mode: DETAIL_MODE_EDIT });
              }}
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>
        }
        {props.authorize?.delete && props.deleteParamsFn &&
          <Tooltip title="Delete" arrow enterDelay={TOOLTIP_DELAY}>
            <IconButton key={value as string} size='small' aria-label="show" style={{ color: 'gray', marginLeft: '6px' }}
              onClick={(event) => {
                event.stopPropagation();
                setDeleteHooks({ ...deleteHooks, open: true, value: value })
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        }
      </Box>
    );
  }

  //  ----------[ Link component ]----------

  const linkField = (column: CBSTableColumn, element: any) => {
    const fieldName = column.field!.substring(LINK_FIELD_PFX.length);
    if (column.linkEnable && !column.linkEnable(element)) {
      return (
        <>
          {element[fieldName] !== null ? element[fieldName].toString() : ""}
        </>
      );
    }

    return (
      <Link
        component={NavLink}
        to="#"
        underline="hover"
        color="primary"
        onClick={(event) => {
          event.stopPropagation();  // If I'm here, _column._link is not _undefined
          event.preventDefault();
          setGoTo(typeof column.link === 'string' ? `${column.link}/${element[fieldName]}` : linkArgumentsCompose(column.link as CBSLinkArguments, element));
        }}
      >
        {element[fieldName] !== null ? element[fieldName].toString() : ""}
      </Link>
    );
  }

  //  ----------[ Row preparation utility ]---------

  const insertRowButtons = () => {
    tableData.forEach((element: any) => {
      if (element.id === undefined) {
        element.id = idWithDictionary(element, props.keyFields);
      }
      if (element[KEY_FIELD_ID] === undefined) {
        element[KEY_FIELD_ID] = createDictionary(element, props.keyFields);
      }

      if (((props.authorize?.detail && detailParams) ||
        (props.authorize?.update && detailParams && detailParams.updateParamsFn && detailParams.updateDetail) ||
        (props.authorize?.delete && props.deleteParamsFn)) && element[OPER_FIELD_ID] === undefined) {
        element[OPER_FIELD_ID] = showRowButtons(element[KEY_FIELD_ID]);
      }

      tableColumns.forEach((column) => {
        if (column.elementFn && column.field) {
          element[column.field] = column.elementFn(element);
        }
        if (column.link && column.field && element[column.field] === undefined) {
          element[column.field] = linkField(column, element);
        }
      });
    });
  }

  //  ----------[ Column reorder utility ]--------

  const reorderDraggedArray = (array: any[], srcIndex: number, dstIndex: number) => {
    const newArray: any[] = [];

    for (let index = 0; index < array.length; index++) {
      if (index !== srcIndex) {
        newArray.push(array[index]);
      }
      if (index === dstIndex) {
        newArray.push(array[srcIndex]);
      }
    }
    return newArray;
  }

  //  ----------[ In memory management utilities ]---------

  const getPage = (dataSource: any[]) => {
    const page: any[] = [];
    const skip = Math.floor(dataSource.length / pagingInfo.top) * pagingInfo.top;    

    let last = Math.min(dataSource.length, pagingInfo.skip + pagingInfo.top);
    let first = Math.min(Math.max(0, pagingInfo.skip), skip);

    if (last > DEFAULT_MAX_SELECTION) {
      last = DEFAULT_MAX_SELECTION;
    }

    if (first < 0) {
      first = 0;
    }

    for (let index = first; index < last; index++) {
      page.push(dataSource[index]);
    }

    return page;
  }

  //  ----------

  const applyFilters = (dataSource: any[]) => {
    const filtered: any[] = [];

    if (filterInfo.filters.length === 0) {
      dataSource.forEach((record) => {
        filtered.push(record);
      });
    }
    else {
      dataSource.forEach((record) => {
        let push = true;
        for (const element of filterInfo.filters) {
          if (element.value !== "" && (record[element.name] === undefined || record[element.name] === null || !applyMultiFilter(record, element))) {
            push = false;
            break;
          }
        }
        if (push) {
          filtered.push(record);
        }
      });
    }

    return filtered;
  }

  function applyMultiFilter(record: any, element: FilterElement) {
    const value = element.parseFn ? element.parseFn(element.value) : element.value;

    if (element.separators) {
      const splitted = value.trim().split(element.separators);

      for (const item of splitted) {
        // The string representation of a boolean is 'true'/'false', the same as the value returned by the boolean combo filter.
        // This condition has never been tested with dates ...
        if (item !== "" && record[element.name] !== null && record[element.name].toString().indexOf(item) === 0) {
          return true;
        }
      };
      return false;
    }

    return record[element.name] !== null && record[element.name].toString().indexOf(value) === 0;
  }

  //  ----------

  const applyOrder = (dataSource: any[], mode?: number) => {
    if (orderInfo.field !== "" && orderInfo.direction !== "") {
      dataSource.sort(orderInfo.direction === SQL_DESCENT ? compareDesc : compareAsc);
    }
    else if (mode === FORCE_ID_REORDER && dataSource[0][KEY_FIELD_ID] !== undefined) {
      dataSource.sort(compareAscId);
    }
    return dataSource;
  }

  function compareAsc(a: any, b: any) {
    if (a[orderInfo.field] < b[orderInfo.field]) { return -1; }
    if (a[orderInfo.field] > b[orderInfo.field]) { return 1; }
    return 0;
  }
  function compareDesc(a: any, b: any) {
    if (a[orderInfo.field] < b[orderInfo.field]) { return 1; }
    if (a[orderInfo.field] > b[orderInfo.field]) { return -1; }
    return 0;
  }
  function compareAscId(a: any, b: any) {
    for (const key of props.keyFields) {
      if (a[KEY_FIELD_ID][key] < b[KEY_FIELD_ID][key]) { return -1; }
      if (a[KEY_FIELD_ID][key] > b[KEY_FIELD_ID][key]) { return 1; }
    }
    return 0;
  }

  //  ----------

  const updateMemoryTable = (mode?: number) => {
    const temporary = applyOrder(applyFilters(memoryData), mode);
    tableData = getPage(temporary);
    insertRowButtons();

    return temporary.length;
  }

  //  ----------[ Service settings ]---------

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authApi = props.authApiRef ? useApi(props.authApiRef) : undefined;
  
  //  ----------[ Hook utilities ]----------

  const setTokenOnParams = async (params: CBSFetchParams): Promise<string | undefined> => {
    if (authApi) {
      const token = await authApi.getAccessToken();

      if (params.request?.headers !== undefined) {
        params.request.headers = { ...params.request.headers, Authorization: `Bearer ${token}` };
      }
      else {
        params.request = { ...params.request, headers: { Authorization: `Bearer ${token}` } };
      }
      return token;
    }
    return undefined;
  }

  //  ----------

  const preparePreQueryInfo = (): CBSQueryInfo  => {
    const queryFilters: CBSQueryInfoFilter[] = [];
    filterInfo.filters.forEach((element) => {
      if (element.value !== "") {
        const value = element.parseFn ? element.parseFn(element.value) : element.value;
        queryFilters.push({ 
          field: element.name, 
          values: element.separators ? value.trim().split(element.separators) : [value], 
          type: element.type
        });
      }
    });

    return { 
      page: { 
        skip: pagingInfo.skip, 
        top: pagingInfo.top 
      }, 
      filters: queryFilters, 
      order: orderInfo.field !== "" && orderInfo.direction !== "" ? { 
        field: orderInfo.field, 
        direction: orderInfo.direction 
      } : 
        undefined
    };
  }

  //  ----------

  const composeStringMultiFilter = (element: FilterElement) => {
    const value = element.parseFn ? element.parseFn(element.value) : element.value;

    if (element.separators) {
      const splitted = value.trim().split(element.separators);

      if (props.settings?.ormDialect === 'odata') {
        let filter: string = "";
        let valid: number = 0;
        splitted.forEach((item) => {
          if (item !== "") {
            filter += `${filter === "" ? "" : " or "}startswith(${element.name},'${item}') eq true`;
            valid++;
          }
        });
        if (valid === 0) {
          return undefined;
        }
        return valid > 1 ? `(${filter})` : filter;
      }

      // For now our dapper implementation doesn't support composition of the "or" filter, so we only handle the first elementt 
      return splitted[0] !== "" ? `${element.name}=${splitted[0]}` : undefined;
    }

    if (props.settings?.ormDialect === 'odata') {
      return `startswith(${element.name},'${value}') eq true`;
    }
    return `${element.name}=${value}`;
  }

  //  ----------

  const composeQueryArgs = (mode: number) => {
    let queryArgs: string = "";

    if (props.inMemory) {
      return queryArgs;
    }

    filterInfo.filters.forEach((element) => {
      if (element.value !== "") {
        switch(element.type) { 
          case 'datetime': { 
            const value = element.parseFn ? element.parseFn(element.value) : element.value;
            if (props.settings?.ormDialect === 'odata') {
              queryArgs += `${queryArgs === "" ? "?$filter=" : " and "}${element.name} eq ${new Date(value).toISOString().substring(0, 19)}`;
            }
            else {
              queryArgs += `${queryArgs === "" ? "?" : "&"}${element.name}=${new Date(value).toISOString().substring(0, 19)}`;
            }
            break; 
          } 
          case 'string': { 
            const filter = composeStringMultiFilter(element);
            if (filter) {
              if (props.settings?.ormDialect === 'odata') {
                queryArgs += `${queryArgs === "" ? "?$filter=" : " and "}${filter}`;
              }
              else {
                queryArgs += `${queryArgs === "" ? "?" : "&"}${filter}`;
              }
            }
            break; 
          } 
          default: { 
            const value = element.parseFn ? element.parseFn(element.value) : element.value;
            if (props.settings?.ormDialect === 'odata') {
              queryArgs += `${queryArgs === "" ? "?$filter=" : " and "}${element.name} eq ${value}`;
            }
            else {
              queryArgs += `${queryArgs === "" ? "?" : "&"}${element.name}=${value}`;
            }
            break; 
          } 
        }
      }
    })

    if (orderInfo.field !== "" && orderInfo.direction !== "") {
      if (props.settings?.ormDialect === 'odata') {
        queryArgs += `${queryArgs === "" ? "?" : "&"}$orderby=${orderInfo.field} ${orderInfo.direction}`;
      }
      else {
        queryArgs += `${queryArgs === "" ? "?" : "&"}sortExpression=${orderInfo.field} ${orderInfo.direction}`;
      }
    }

    if (mode === PAGED_QUERY) {
      if (props.settings?.ormDialect === 'odata') {
        queryArgs += `${queryArgs === "" ? "?" : "&"}$skip=${pagingInfo.skip}&$top=${pagingInfo.top}`;
      }
      else {
        queryArgs += `${queryArgs === "" ? "?" : "&"}skip=${pagingInfo.skip}&top=${pagingInfo.top}`;
      }
    }

    return queryArgs;
  }

  //  ----------

  const verifyLastLine = () => {
    if (pagingInfo.skip >= counter - 1) {
      pagingInfo.skip = Math.max(pagingInfo.skip - pagingInfo.top, 0);
    }
  }

  //  ----------

  const refreshElementFunctions = () => {
    tableData.forEach((element: any) => {
      tableColumns.forEach((column) => {
        if (column.elementFn && column.field) {
          element[column.field] = column.elementFn(element);
        }
      });
    });
  }

  //  ----------

  const updateTable = (mode?: number) => {
    refreshElementFunctions();
    if (props.inMemory) {
      setCounter(updateMemoryTable(mode));
      setRefreshMemory(refreshMemory + 1);
    }
    else {
      setUpdater(updater + 1);
    }
  }

  const refreshTable = () => {
    refreshElementFunctions();
    setUpdater(updater + 1);
  }

  const changeElementFn = useCallback((field: string, elementFn: (item: any) => JSX.Element) => {
      tableColumns.forEach((column) => {
        if (column.elementFn && column.field === field) {
          column.elementFn = elementFn;
        }
      });
      refreshElementFunctions();
      setRefresh(refresh + 1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refresh]
  );

  const reloadTable = useCallback(() => {
      updateTable();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updater]
  );

  //  ----------[ Hook reference ]---------

  useImperativeHandle(ref, () => { 
    return { 
      changeElementFn,
      reloadTable
    }; 
  }, [changeElementFn, reloadTable]);

  //  ----------[ Initial load hook ]---------

  const result = useAsync(async (): Promise<any> => {
    const params = props.readParamsFn();
    const token = await setTokenOnParams(params);

    if (params.preQueryFn !== undefined) {
      params.preQueryFn(params.request, preparePreQueryInfo());
    }

    const response = await fetch(`${props.baseUrl}${params.url}${params.request?.method !== "POST" ? composeQueryArgs(PAGED_QUERY) : ""}`, params.request);
    if (response.status !== 200) {
      throw new Error(`Failed to fetch: ${response.url}, status: ${response.status}, reason: ${response.statusText}`);
    }

    let record = await response.json();
    if (params.postQueryFn !== undefined) {
      record = await params.postQueryFn(record, { baseUrl: props.baseUrl, token: token });
    }

    if (props.inMemory) {
      setMemoryData(record.items);
      tableData = getPage(record.items);
    }
    else {
      tableData = record.items;
    }

    insertRowButtons();
    setCounter(record.totalCount);

    return record;
  }, [updater]);

  //  ----------[ Refreshing hooks ]----------

  useEffect(() => {
    if (detailHooks.reload > 0) {
      if (props.inMemory) {
        if (detailHooks.value.length === 0) { // may not always have possibility to reload from ID
          updateTable(FORCE_ID_REORDER);
        }        
        else if (detailHooks.mode === DETAIL_MODE_CREATE) {
          memoryData.push(detailHooks.value[0]);
          if (applyFilters(detailHooks.value).length === 0) {
            verifyLastLine();
          }
          updateTable(FORCE_ID_REORDER);
        }
        else if (detailHooks.mode === DETAIL_MODE_EDIT) {
          const index = findWithDictionary(memoryData, detailHooks.value[0], props.keyFields);
          if (index !== -1) {
            memoryData[index] = detailHooks.value[0];
            if (applyFilters(detailHooks.value).length === 0) {
              verifyLastLine();
            }
            updateTable(FORCE_ID_REORDER);
          }
        }
      }
      else {
        if (detailHooks.value.length === 0) { // may not always have possibility to reload from ID
          updateTable();
        }
        else if (detailHooks.mode === DETAIL_MODE_CREATE) {
          if (applyFilters(detailHooks.value).length > 0) {
            updateTable();
          }
        }
        else if (detailHooks.mode === DETAIL_MODE_EDIT) {
          const index = findWithDictionary(tableData, detailHooks.value[0], props.keyFields);
          if (index !== -1) {
            if (applyFilters(detailHooks.value).length === 0) {
              verifyLastLine();
              updateTable();
            }
            else {
              tableData[index] = detailHooks.value[0];
              insertRowButtons();
              setRefresh(refresh + 1);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailHooks.reload]);

  //  ----------

  useEffect(() => {
    if (deleteHooks.reload > 0) {
      if (props.inMemory) {
        const index = findWithDictionary(memoryData, deleteHooks.value, props.keyFields);
        memoryData.splice(index, 1);
      }
      verifyLastLine();
      updateTable(FORCE_ID_REORDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteHooks.reload]);

  //  ----------

  const deleteFunction = async (dictionary: any): Promise<DialogOperationResult> => {
    const params = props.deleteParamsFn!(dictionary);   // Delete function is assigned only if _deleteParams is defined
    await setTokenOnParams(params);

    const response = await fetch(`${props.baseUrl}${params.url}`, params.request);
    const status = await response.json();

    return ({
      isSuccess: status.isSuccess,
      detectedProblems: status.detectedProblems
    });
  }

  //  ----------[ Navigation hook ]---------

  useEffect(() => {
    if (goTo !== '') {
      navigate(goTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goTo]);

  //  ----------[ Export hook ]---------

  const exportFunction = async (memData: any[]): Promise<DialogOperationResult> => {
    let items: any;

    if (props.inMemory) {
      const temporary = applyOrder(applyFilters(memData));
      items = temporary.map((row) => {
        const element = { ...row };

        delete element.id;
        delete element[KEY_FIELD_ID];
        delete element[OPER_FIELD_ID];

        for (const [key] of Object.entries(element)) {
          if (key.startsWith(LINK_FIELD_PFX)) {
            delete element[key];
          }
        };

        return element;
      });
    }

    else {
      const params = props.readParamsFn();
      const token = await setTokenOnParams(params);

      if (params.preQueryFn !== undefined) {
        params.preQueryFn(params.request, preparePreQueryInfo());
      }

      const response = await fetch(`${props.baseUrl}${params.url}${params.request?.method !== "POST" ? composeQueryArgs(FULL_QUERY) : ""}`, params.request);
      if (response.status !== 200) {
        throw new Error(`Failed to fetch: ${response.url}, status: ${response.status}, reason: ${response.statusText}`);
      }

      let record = await response.json();
      if (params.postQueryFn !== undefined) {
        record = await params.postQueryFn(record, { baseUrl: props.baseUrl, token: token });
      }
      items = record.items;
    }

    const worksheet = XLSX.utils.json_to_sheet(items);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, props.title);
    XLSX.writeFile(workbook, `${props.title}.xlsx`, { compression: true });

    return ({
      isSuccess: true,
      detectedProblems: []
    });
  }

  //  ----------

  const verifyExportFunction = () => {
    if (counter > (props.settings?.maxSelection || DEFAULT_MAX_SELECTION)) {
      setExportHooks({ ...exportHooks, open: true, value: memoryData });
    }
    else if (counter > 0) {
      exportFunction(memoryData);
    }
  }

  //  ----------[ Callbacks ]----------

  const pagingCallback = (info: PagingCardInfo) => {
    pagingInfo = info;
    updateTable();
  };

  const filterConfirmDelegate = (confirmFn: () => void) => {
    if (props.delegate?.filterConfirmCallback) {
      props.delegate.filterConfirmCallback(confirmFn);
    }
    else {
      confirmFn();
    }
  };

  const filterApplyCallback = (info: FilterCardInfo) => {
    filterInfo = info;
    pagingInfo.skip = 0;
    updateTable();
  };

  const orderChangeCallback = (orderBy: number) => {
    let column = orderBy;
    // Something weird happens: if I assign the _defaultSort property, after a couple of reorders on the same column I receive negative _orderBy.
    // This is a patch I don't like, but it allows me to overcome the problem, although not too elegantly.
    // I don't want to give up the opportunity to highlight the sorted column ...
    if (column === -1) {
      for (let index = 0; index < tableColumns.length; index++) {
        const fieldName = tableColumns[index].link ? tableColumns[index].field!.substring(LINK_FIELD_PFX.length) : tableColumns[index].field;
        if (fieldName === orderInfo.field) {
          column = index;
          break;
        }
      }
    }

    if (column !== -1) {
      for (let index = 0; index < tableColumns.length; index++) {
        if (index !== column) {
          tableColumns[index].defaultSort = undefined;
          tableColumns[index].nextSort = SQL_ASCENT;
        }
      }

      orderInfo.field = tableColumns[column].link ? tableColumns[column].field!.substring(LINK_FIELD_PFX.length) : tableColumns[column].field as string;
      orderInfo.direction = tableColumns[column].nextSort || SQL_ASCENT;
      tableColumns[column].nextSort = tableColumns[column].nextSort === SQL_ASCENT ? SQL_DESCENT : SQL_ASCENT;
      tableColumns[column].defaultSort = orderInfo.direction === SQL_ASCENT ? 'asc' : 'desc';   // Cannot use constants due to _defaultSort field constraints

      updateTable();
    }
  };

  const onColumnDraggedCallback = (srcIndex: number, dstIndex: number) => {
    tableColumns = reorderDraggedArray(tableColumns, srcIndex, dstIndex);
    updateTable();
  }

  const onRowClickCallback = (_?: React.MouseEvent<Element, MouseEvent>, rowData?: any) => {
    if (window.getSelection()?.toString() === '') {
      setDetailHooks({ ...detailHooks, open: true, value: rowData[KEY_FIELD_ID], mode: DETAIL_MODE_SHOW });
    }
  }

  //  ----------[ Components utilities ]----------

  const chooseFilterIcon = (): string => {
    let count = 0;
    filterInfo.filters.forEach((filter) => {
      if (filter.value !== "") {
        count++;
      }
    });

    return darkTheme ? darkFilters[Math.min(count, 9)] : lightFilters[Math.min(count, 9)];
  }

  //  ----------[ Title components ]----------

  const tableTitle = (text: string) => {
    return (
      <Grid container spacing={1} direction="row" alignItems="center" style={{ padding: "12px 16px 12px 16px" }} >
        {filterInfo.filters.length > 0 &&
          <Grid item>
            <Tooltip title={filterOpen ? "Close filter panel" : "Open filter panel"} arrow enterDelay={TOOLTIP_DELAY}>
              <IconButton className={classes.iconButton} size='small' color='primary' aria-label="refresh"
                onClick={() => setFilterOpen(!filterOpen)} >
                {filterOpen ? <ArrowBackIcon /> : <img src={chooseFilterIcon()} alt="F" width="18" />}
              </IconButton>
            </Tooltip>
          </Grid>
        }
        <Grid item style={{ flexGrow: 1 }}>
          <Typography variant="h5" style={{ marginTop: 0, marginBottom: 0, fontWeight: 'bold', paddingLeft: '6px' }}>
            {text}
          </Typography>
        </Grid>
        {createNew &&
          <Grid item>
            <Tooltip title="Add new record" arrow enterDelay={TOOLTIP_DELAY}>
              <IconButton className={classes.iconButton} size='small' color='primary' aria-label="refresh"
                onClick={() => setDetailHooks({ ...detailHooks, open: true, value: undefined, mode: DETAIL_MODE_CREATE })} >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        }
        <Grid item>
          <Tooltip title="Export to Excel" arrow enterDelay={TOOLTIP_DELAY}>
            <IconButton className={classes.iconButton} size='small' color='primary' aria-label="refresh"
              onClick={() => verifyExportFunction()} >
              <Exporticon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Refresh" arrow enterDelay={TOOLTIP_DELAY}>
            <IconButton className={classes.iconButton} size='small' color='primary' aria-label="refresh"
              onClick={() => refreshTable()} >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  //  ----------[ Fetch components ]----------

  const FetchPagedTable = () => {
    return (
      <div style={{ position: "relative" }}>
        {result.loading && counter > -1 && tableData !== undefined &&
          <Backdrop className={backdropClass.root} open>
            <CircularProgress color="primary" />
          </Backdrop>
        }
        <Table
          key={refresh > 0 ? "Table" : "Table"}   // Dummy control to force rendering on _refresh changes
          components={{
            Toolbar: (_) => tableTitle(props.title)
          }}
          options={{ search: false, paging: false, padding: 'dense', cspNonce: getCspNonce() }}
          columns={tableColumns}
          data={tableData}
          onOrderChange={orderChangeCallback}
          onColumnDragged={onColumnDraggedCallback}
          onRowClick={props.settings?.disableRowClick === false && props.authorize?.detail && detailParams ? onRowClickCallback : undefined}
        />
      </div>
    );
  }

  //  ----------

  const FetchFullTable = () => {
    return (
      <div style={{ position: "relative" }}>
        {result.loading && counter > -1 && tableData !== undefined &&
          <Backdrop className={backdropClass.root} open>
            <CircularProgress color="primary" />
          </Backdrop>
        }
        <Table
          key={refreshMemory > 0 ? "InMemory" : "InMemory"}   // Dummy control to force rendering on _refreshMemory changes
          components={{ Toolbar: (_) => tableTitle(props.title) }}
          options={{ search: false, paging: false, padding: 'dense', cspNonce: getCspNonce() }}
          columns={tableColumns}
          data={tableData}
          onOrderChange={orderChangeCallback}
          onColumnDragged={onColumnDraggedCallback}
          onRowClick={props.settings?.disableRowClick === false && props.authorize?.detail && detailParams ? onRowClickCallback : undefined}
        />
      </div>
    );
  }

  //  ----------[ Detail component ]----------

  const TableDetail = () => {
    if (props.authorize?.detail && detailParams !== undefined) {
      // eslint-disable-next-line new-cap
      return CBSDetailPopup(
        detailParams,
        detailHooks,
        setDetailHooks,
        props.themeId,
        props.baseUrl,
        props.readParamsFn,
        props.keyFields,
        props.authApiRef,
      );
    }

    return <div />;
  };

  //  ----------[ Delete dialog component ]----------

  const DeleteDialog = () => {
    if (props.deleteParamsFn !== undefined) {
      // eslint-disable-next-line new-cap
      return CBSRequestDialog(
        {
          title: "DELETE",
          themeId: props.themeId,
          message: "Once deleted, the data can no longer be recovered. Are you sure you want to proceed?",
          operation: "DELETE",
          operating: "DELETING",
          operationFn: deleteFunction,
          keyFields: props.keyFields
        },
        deleteHooks,
        setDeleteHooks
      );
    }

    return <div />;
  };

  //  ----------[ Export dialog component ]----------

  const ExportDialog = () => {
    // eslint-disable-next-line new-cap
    return CBSRequestDialog(
      {
        title: "Export to Excel",
        themeId: props.themeId,
        message: `Only the first ${props.settings?.maxSelection || DEFAULT_MAX_SELECTION} selected rows will be exported`,
        operation: "EXPORT",
        operating: "EXPORTING",
        operationFn: exportFunction
      },
      exportHooks,
      setExportHooks
    );
  };

  //  ----------[ Paged table object ]----------

  if (result.loading && (counter < 0 || tableData === undefined)) {  // First load only
    return (
      <Grid container direction="column" alignContent="center" alignItems="center" spacing={2}
        style={{ position: 'relative', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </Grid>
    );
  }

  if (result.error) {
    return (
      <Alert severity="error">{result.error.message}</Alert>
    );
  }

  //  ---------

  return (
    <>
      <Grid container spacing={1} direction="column">
        <Grid item style={{ width: '100%' }}>
          <Grid container spacing={1} direction="row">
            {filterOpen &&
              <Grid item xs={2}>
                <CBSFilterCard
                  filterInfo={filterInfo}
                  filterConfirmDelegate={filterConfirmDelegate}
                  filterApplyCallback={filterApplyCallback}
                />
              </Grid>
            }
            <Grid item xs={filterOpen ? 10 : 12}>
              {props.inMemory ? <FetchFullTable /> : <FetchPagedTable />}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {counter > 0 &&
            <CBSPagingCard
              totalCount={counter}
              pagingInfo={pagingInfo}
              pagingCallback={pagingCallback}
            />
          }
        </Grid>
      </Grid>
      <TableDetail />
      <DeleteDialog />
      <ExportDialog />
    </>
  );
});

